<template>
  <div v-if="order">
    <div class="h c" v-if="payInfo">
      <div class="flex">
        <form-info-item label-position="right" label-suffix=":" :label="receipt ? '销售单号' : '采购单号'" v-if="!mall">
          <b>{{order.formCode}}</b>
        </form-info-item>

        <form-info-item label-position="right" label-suffix=":" label="支付方式" v-else>{{payTypes[order.payType]}}</form-info-item>
        <form-info-item label-position="right" label-suffix=":" label="支付状态">
          <dot same :type="orderPayStatus[order.payType][order.payStatus].type">{{orderPayStatus[order.payType][order.payStatus].label}}</dot>
        </form-info-item>
      </div>
      <div class="money-count-item">
        <div class="fc-g">应付金额</div>
        <counter class="num" :end-val="payInfo.orderTotalMoney / 100" :decimals="2" :duration="1" />
      </div>
      <div class="money-count-item">
        <div class="fc-g">成功支付</div>
        <counter class="num" :end-val="payInfo.realPayMoney / 100" :decimals="2" :duration="1" />
      </div>
      <div class="money-count-item">
        <div class="fc-g">使用返利</div>
        <counter class="num" :end-val="payInfo.usedRebate / 100" :decimals="2" :duration="1" />
      </div>
      <!-- <div class="money-count-item">
        <div class="fc-g">待付款金额</div>
        <counter class="num" :end-val="payInfo.remainMoney / 100" :decimals="2" :duration="1" />
      </div>-->
    </div>
    <el-table :data="data" row-key="id" border empty-text="暂无支付记录" style="margin: 10px 0;">
      <el-table-column prop="code" label="支付单号" width="120" />
      <el-table-column label="支付日期" width="90" :formatter="r => {return new Date(r.payTime).format('yyyy/MM/dd');}" />
      <el-table-column prop="payMoney" label="支付金额" align="right" width="100" :formatter="$price" />
      <el-table-column key="channel" label="支付渠道" v-if="order.payType === 0" :formatter="v=>{return (v.payChannel?payChannels[v.payChannel]:'') + (payWays[v.tradeType]?'【'+payWays[v.tradeType]+'】':'')}" width="140" />
      <el-table-column prop="accountMoney" label="使用余额" align="right" width="100" :formatter="$price" />
      <el-table-column key="fee" label="手续费" align="right" width="160" v-if="order.payType === 0">
        <template slot-scope="scope">
          <template v-if="receipt">
            <div>{{$price(scope.row.fee)}}</div>
            <div class="fs-mini fc-g lh-150">
              经销商承担{{$price(scope.row.payFee)}}
              <br />
              厂商承担{{$price(scope.row.fee - scope.row.payFee)}}
            </div>
          </template>
          <span v-else>{{$price(scope.row.payFee)}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="payRebate" label="使用返利" align="right" width="120" :formatter="$price" /> -->
      <el-table-column prop="info" label="说明" show-overflow-tooltip min-width="200" />
      <el-table-column label="状态" width="90" align="center">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.payType][scope.row.status].type">{{status[scope.row.payType][scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :width="order.payType === 0 ? 130 : 45">
        <div class="row-commands" slot-scope="scope">
          <template v-if="!receipt && scope.row.payType === 0 && scope.row.status === 0">
            <el-button type="text" size="mini" class="deep-danger" @click="onlineRepay(scope.row)">支付</el-button>
            <el-button type="text" size="mini" class="deep-danger" @click="onlineCancelRepay(scope.row)">刷新</el-button>
          </template>
          <el-button type="text" size="mini" @click="detail(scope.row)">查看</el-button>
        </div>
      </el-table-column>
    </el-table>
    <div v-if="!receipt && payable && payInfo" v-show="payInfo.remainMoney > 0">
      <template v-if="order.payType === 0">
        <el-divider content-position="left">支付</el-divider>
        <online-pay ref="online" type="purchase" :money="payInfo.remainMoney" :supplier="order.sellerId" :order-form-id="order.id" @paied="handlePaied" />
      </template>
      <el-button type="primary" @click="pay" v-else>{{data && data.length ? '继续支付' : '立即支付'}}</el-button>
    </div>

    <pay-form ref="form" :receipt="receipt" @paied="handlePaied" />
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import counter from "vue-count-to";
import request from "@/utils/request";
import payForm from "./pay-form";
import onlinePay from "@/views/assembly/onlinePay";

export default {
  components: { counter, payForm, onlinePay },
  mixins: [initData],
  props: {
    mall: Boolean,
    order: Object,
    receipt: Boolean,
  },

  data() {
    return {
      payInfo: null,
      payTypes: ["线上支付", "线下支付"],
      payChannels: { thirdPay: "扫码支付", unionPay: "银联支付" },
      payWays: { "pay.weixin.jspay": "微信", "pay.alipay.jspay": "支付宝" },
      orderPayStatus: [
        [
          {
            type: "warning",
            label: "待付款",
          },
          {
            type: "warning",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分付款成功",
          },
          {
            type: "warning",
            label: "待付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "付款成功",
          },
        ],
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分已付款",
          },
          {
            type: "warning",
            label: "部分付款已确认",
          },
          {
            type: "success",
            label: "已付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "已完成",
          },
        ],
      ],
      status: [
        [
          {
            type: "info",
            label: "待支付",
          },
          {
            type: "success",
            label: "已支付",
          },
          {
            type: "danger",
            label: "支付失败",
          },
        ],
        [
          {
            type: "info",
            label: "待审批",
          },
          {
            type: "success",
            label: "已通过",
          },
          {
            type: "danger",
            label: "未通过",
          },
        ],
      ],
    };
  },
  computed: {
    payable() {
      if (this.order) {
        if (this.order.payType === 1)
          return [0, 1, 3, 9, 10].indexOf(this.order.status) < 0;
        else return [3, 9, 10].indexOf(this.order.status) < 0;
      }
      return false;
    },
  },
  watch: {
    order: {
      deep: true,
      handler: "reload",
    },
  },
  methods: {
    reload() {
      this.toQuery();
      this.loadPayInfo();
    },
    beforeInit() {
      this.url = "api/payForm/orderForm/" + this.order.id;
      return true;
    },
    loadPayInfo() {
      if (this.order && this.order.id) {
        request({
          url: "api/orderForm/payInfo",
          method: "post",
          data: {
            orderFormId: this.order.id,
          },
        }).then((res) => {
          res.remainMoney = res.orderTotalMoney - res.useMoney - res.usedRebate;
          if (res.remainMoney < 0) res.remainMoney = 0;
          this.payInfo = res;
        });
      }
    },
    handlePaied() {
      this.toQuery();
      this.loadPayInfo();
      this.$emit("paied");
    },
    detail(row) {
      this.$refs.form && this.$refs.form.resetForm(row);
    },
    pay() {
      this.$refs.form &&
        this.$refs.form.resetForm(null, this.order, this.payInfo.remainMoney);
    },
    onlineRepay(row) {
      this.$refs.online && this.$refs.online.pay(row);
    },
    onlineCancelRepay(row) {
      this.$confirm("您确定要重新获取该支付单状态吗？", "操作确认", {
        type: "warning",
      }).then(() => {
        request({
          url: "api/payOnLine/cancel/" + row.id,
          method: "post",
        }).then((res) => {
          this.$message.success("刷新成功");
          this.reload();
        });
      });
    },
  },
  mounted() {
    this.reload();
  },
};
</script>