<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select url="api/warehouse" v-model="query.warehouseIds" multiple filterable collapse-tags placeholder="仓库" class="filter-item" @change="toQuery" clearable style="width: 240px;" />
      <quick-select url="api/brand" v-model="query.brandId" filterable placeholder="品牌" class="filter-item" @change="toQuery" clearable style="width: 160px;" />
      <!-- <quick-cascader class="filter-item" v-model="query.seriesId" url="api/series/tree" :params="{enabled: true}" root-key @change="toQuery" check-strictly filterable clearable placeholder="系列" expand-trigger="hover" style="width: 160px;" /> -->
        <quick-cascader class="filter-item" v-model="query.seriesIds" url="api/series/tree" :params="{enabled: true,brandId:query.brandId}" root-key @change="toQuery" filterable clearable placeholder="系列" expand-trigger="hover" style="width: 250px;" multiple checkStrictly collapseTags/>      
      <quick-cascader class="filter-item" v-model="query.categoryId" url="api/category/tree" @change="toQuery" filterable clearable placeholder="分类" expand-trigger="hover" style="width: 160px;" />
      <!-- <el-input-number class="filter-item" v-model="query.minCount" :precision="0" :min="0" :controls="false" placeholder="最小库存" style="width: 100px;" />
      <el-input-number class="filter-item" v-model="query.maxCount" :precision="0" :min="0"  :controls="false" placeholder="最大库存" style="width: 100px;" />-->
      <input class="filter-item el-input__inner" type="number" v-model.number="query.minCount" min="0" placeholder="最小库存" style="width: 100px;" />
      <input class="filter-item el-input__inner" type="number" v-model.number="query.maxCount" min="0" placeholder="最大库存" style="width: 100px;" />
      <el-input v-model="query.name" :maxlength="20" clearable placeholder="输入商品名称搜索" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.code" :maxlength="20" clearable placeholder="输入商品编码搜索" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.specs" clearable placeholder="输入规格搜索" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <!-- <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button> -->
      <div style="display: inline-block;margin: 0px 2px;">
        <el-dropdown szie="mini" type="primary" @click="toDownload" @command="downloadCmd" class="filter-item">
          <el-button type="info" icon="el-icon-download">导出</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="summary">库存汇总</el-dropdown-item>
            <el-dropdown-item command="detail">库存明细</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-card shadow="never">
      <div class="h">
        <div class="money-count-item">
          <div class="fc-g">库存商品总数</div>
          <counter class="num" :end-val="goodsCount" :decimals="2" :duration="1" />
        </div>
        <div class="money-count-item" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
          <div class="fc-g">库存商品成本</div>
          <counter class="num" :end-val="goodsMoney / 100" :decimals="2" :duration="1" />
        </div>
      </div>
    </el-card>
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="code" label="商品编码" width="130" fixed show-overflow-tooltip/>
      <el-table-column prop="erpCode" label="ERP编码" width="130" fixed show-overflow-tooltip/>
      <el-table-column prop="name" label="商品名称" min-width="180px" fixed />
      <el-table-column prop="specs" label="规格" min-width="160" :formatter="$goodsSpecConvert" show-overflow-tooltip/>
       <el-table-column label="上下架" width="70" fixed="right">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.onShelf" size="mini" disabled/>
          </template>
        </el-table-column>
      <el-table-column prop="countInfo.warehouseCount" label="库存" width="120px" align="right" />
      <el-table-column prop="countInfo.saleCount" label="待发货数量" width="120px" align="right" />      
      <el-table-column prop="countInfo.purchaseCount" label="在途数量" width="120px" align="right" />
      <el-table-column label="可用数量" width="120px" align="right">
        <b class="fc-s" slot-scope="scope">{{(scope.row.countInfo.warehouseCount + scope.row.countInfo.purchaseCount - scope.row.countInfo.saleCount).toFixed(2)}}</b>
      </el-table-column>
      <el-table-column prop="countInfo.deliveryUnSendCount" label="通知待发货数量" width="120px" align="right">
        <template slot-scope="scope">{{scope.row.countInfo.deliveryUnSendCount < 0 ? 0 : scope.row.countInfo.deliveryUnSendCount}}</template>
      </el-table-column>
      <el-table-column label="成本均价" width="120px" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
        <template slot-scope="scope">{{$price(scope.row.countInfo.money)}}</template>
      </el-table-column>
      <el-table-column label="成本小计" width="120px" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
        <template slot-scope="scope">{{$price(scope.row.countInfo.money * scope.row.countInfo.warehouseCount)}}</template>
      </el-table-column>
      <el-table-column prop="brandName" label="品牌" width="120px" />
      <el-table-column prop="categoryName" label="分类" width="120px" />
      <el-table-column prop="seriesName" label="系列" width="120px" />
      <el-table-column width="80px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">库存明细</el-button>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <detail-dialog ref="detail" />
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import detailDialog from "./detail";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import Counter from "vue-count-to";
export default {
  mixins: [initData],
  components: { detailDialog, Counter },
  data() {
    return {
      downloadLoading: false,
      goodsCount: 0,
      goodsMoney: 0,
      query: {
        warehouseIds: null,
        brandId: null,
        seriesId: null,
        categoryId: null,
        minCount: 0.01,
        maxCount: null,
        name: null,
        code: null,
      },
    };
  },

  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/warehouseGoods";
      // this.params = Object.assign(
      //   { page: this.page, size: this.size, },
      //   this.query
      // );
      this.params = this.query;
      return true;
    },
    downloadCmd(ac) {
      if (ac === "detail"){
          this.toDownload("api/warehouseGoods/detail/download");
      }else{
        this.toDownload();
      }      
    },
    afterLoadFull(res) {
      this.goodsCount = res.goodsCount;
      this.goodsMoney = res.goodsMoney;
    },
    detail(row) {
      this.$refs.detail && this.$refs.detail.reset(row,this.query.warehouseIds);
    },
    toDownload(url) {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      if(this.checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])){
        this.params.showPrice=true;
      }
      
      download(url?url:"api/warehouseGoods/download", this.params)
        .then((result) => {
          downloadFile(result, "库存", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>