<template>
  <div class="app-container" v-if="statementType">
    <div class="head-container">
      <quick-select v-model="query.friendEntId" :label.sync="distributorName" filterable url="api/distributor" placeholder="选择经销商" value-field="enterpriseId" auto-select-first-option class="filter-item" style="width: 180px;" @change="reQuery" />
      <!-- <el-date-picker v-if="statementType===sertaStatementType" v-model="query.yearAndMonth" type="month" :clearable="false" value-format="yyyy-M" placeholder="选择会计期间" @change="reQuery" style="width: 160px;" /> -->
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="记录日期起始" end-placeholder="记录日期截止" :picker-options="searchDateRangeOptions" value-format="timestamp" :clearable="false" :default-time="['00:00:00', '23:59:59']" @change="reQuery" style="width: 240px;" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="reQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="primary" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-card shadow="never" v-if="count">
      <div class="h">
        <div class="money-count-item">
          <div class="fc-g">返利余额</div>
          <counter class="num" :class="count.rebateBalance < 0 ? 'fc-e' : ''" :end-val="count.rebateBalance / 100" :decimals="2" :duration="1" />
        </div>
      </div>
    </el-card>
    <el-table v-loading="loading" row-key="id" :data="data" highlight-current-row empty-text="未找到返利信息" :default-sort="{prop: 'recordTime', order: 'ascending'}" @sort-change="handleSort">
      <!-- <el-table-column type="index" fixed/> -->
      <el-table-column prop="recordTime" label="单据日期" min-width="180" :formatter="r => { return r.recordTime?new Date(r.recordTime).format():'' }" sortable="custom" />
      <el-table-column prop="recordType" label="操作" min-width="100" sortable="custom">
        <template slot-scope="scope">{{recordTypes[scope.row.recordType || 0]}}</template>
      </el-table-column>
      <el-table-column prop="relationFormType" label="单据类型" min-width="150" sortable="custom">
        <template slot-scope="scope">{{relationFormType[scope.row.relationFormType]}}</template>
      </el-table-column>
      <el-table-column prop="relationFormCode" label="单据编号" min-width="160" sortable="custom" />
      <el-table-column prop="info" label="备注" min-width="320" show-overflow-tooltip />
      <el-table-column prop="rebate" label="使用返利金" min-width="120" align="right" :formatter="$price" />
      <el-table-column prop="rebateBalance" label="返利余额" min-width="120" align="right" :formatter="$price" />
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import { getByDistributor, getStatementType } from "@/api/capitalPool";
import Counter from "vue-count-to";
import PriceRangeInput from "@/views/components/common/priceRangeInput";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  mixins: [initData],
  components: { Counter, PriceRangeInput },
  data() {
    return {
      downloadLoading: false,
      statementType: null,
      sertaStatementType: "serta",
      distributorName: null,
      queryable: false,
      query: {
        friendEntId: null,
        yearAndMonth: new Date(),
        dateRange: [],
        recordType: null,
        payableMin: null,
        payableMax: null,
        payMoneyMin: null,
        payMoneyMax: null,
      },
      count: null,
      sort: "recordTime,asc",
      recordTypes: [
        "采购下单",
        "采购付款",
        "退货退款",
        "充值",
        "提现",
        "返利入账",
        "返利出账",
        "费用单下单",
        "费用单付款",
        "其它",
      ],
      relationFormType: {
        order: "销售单",
        purchase: "采购单",
        orderBack: "退单",
        payForm: "收退款单",
        rebateApply: "返利申请单",
        bill: "费用单",
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/capitalPool/seller";
      let query = JSON.parse(JSON.stringify(this.query));
      let required = false;
      if (query.payable == "") delete query.payable;
      if (query.payMoney == "") delete query.payMoney;
      if (query.friendEntId) {
        if (query.dateRange && query.dateRange.length === 2) {
          required = true;
          query.begTime = query.dateRange[0];
          query.endTime = query.dateRange[1];
        }
        delete query.dateRange;
      }
      if (query.friendEntId && query.yearAndMonth) {
        required = true;
      }
      query.rebateNot = 0;
      if (required) {
        this.params = query;
        this.params.sort = this.sort;
      } else {
        this.$message.info("请选择经销商和记录日期范围进行搜索。");
      }
      this.queryable = required;
      return required;
    },
    handleSort(sort) {
      if (sort.order == null) this.sort = "";
      else
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      this.toQuery();
    },
    calcCount() {
      // if(this.statementType === this.sertaStatementType) return;
      if (this.queryable && this.params) {
        getByDistributor(this.params.friendEntId).then((res) => {
          this.count = res;
        });
      }
    },
    reQuery() {
      this.toQuery();
      this.calcCount();
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/rebate/download", this.params)
        .then((result) => {
          downloadFile(result, this.distributorName + "_返利明细", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
  mounted() {
    let now = new Date();
    this.query.dateRange = [
      new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate(),
        0,
        0,
        0
      ).getTime(),
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        23,
        59,
        59
      ).getTime(),
    ];
    getStatementType().then((res) => {
      this.statementType = res;
    });
  },
};
</script>