<template>
  <div class="app-container flex v">
    <div class="head-container">
      <!-- <quick-select v-model="query.friendEntId" filterable url="api/distributor" placeholder="经销商" value-field="enterpriseId" clearable @change="toQuery" class="filter-item" style="width: 210px;" /> -->
      <el-input v-model="query.code" clearable placeholder="单号、关联单号搜索" style="width: 170px;" class="filter-item" @keyup.enter.native="toQuery" />
      <quick-select class="filter-item" v-model="query.friendEntId" filterable value-field="enterpriseId" url="api/distributor" :filterMethod="(s, d) => {
                return s.filter(o => {
                  return (o.erpId || '').toLowerCase().indexOf(d) >= 0 || (o.name || '').toLowerCase().indexOf(d) >= 0;
                });
              }"
        placeholder="请选择经销商" @change="toQuery" style="width: 230px;" clearable>
        <template slot-scope="scope">（{{scope.row.erpId}}）{{scope.row.name}}</template>
      </quick-select>
      <el-select v-model="query.dateType" class="filter-item" filterable clearable placeholder="日期类型" style="width: 100px;" @change="toQuery">
        <el-option :value="0" label="支付日期" />
        <el-option :value="1" label="审核日期" />
      </el-select>
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="起始" end-placeholder="截止" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" style="width: 240px;" @change="toQuery" />
      <el-select v-model="query.inOutType" class="filter-item" filterable clearable placeholder="方向" style="width: 100px;" @change="toQuery">
        <el-option value="in" label="收入" />
        <el-option value="out" label="支出" />
      </el-select>
      <el-select v-model="query.payType" class="filter-item" filterable clearable placeholder="支付方式" style="width: 100px;" @change="toQuery">
        <el-option :value="0" label="在线支付" />
        <el-option :value="1" label="线下支付" />        
      </el-select>
      <el-select v-model="query.status" class="filter-item" filterable clearable placeholder="付款状态" style="width: 100px;" @change="toQuery">
        <el-option :value="0" label="待审批" />
        <el-option :value="1" label="已通过" />
        <el-option :value="2" label="未通过" />
      </el-select>
      <el-select v-model="query.relationFormType" class="filter-item" filterable clearable placeholder="收支类别" style="width: 100px;" @change="toQuery">
        <el-option :value="0" label="销售" />
        <el-option :value="2" label="提现" />
        <el-option :value="3" label="充值" />
        <el-option :value="4" label="费用" />
        <el-option :value="5" label="质保金" />
        <el-option :value="1" label="其他" />
      </el-select>
      <el-select v-model="query.payChannel" class="filter-item" filterable clearable placeholder="ERP结算方式" style="width: 130px;" @change="toQuery">
        <el-option v-for="(o, k) in erpPayChannels" :key="k" :value="k" :label="o" />
      </el-select>
      <el-select v-if="query.payChannel==='thirdPay'" v-model="query.tradeType" class="filter-item" filterable clearable placeholder="交易类型" style="width: 100px;" @change="toQuery">
        <el-option v-for="(o, k) in payWays" :key="k" :value="k" :label="o" />
      </el-select>
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-minus" @click="addCashout">提现</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="addCashin">充值</el-button>
      <el-button class="filter-item" type="info" icon="el-icon-upload" @click="upload">充值导入</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-card shadow="never" v-if="count">
      <div class="h">
        <div class="money-count-item">
          <div class="fc-g">收入/支出合计</div>
          <counter class="num" :class="count.payMoney < 0 ? 'fc-e' : ''" :end-val="count.payMoney / 100" :decimals="2" :duration="1" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">使用余额合计</div>
          <counter class="num" :class="count.accountMoney < 0 ? 'fc-e' : ''" :end-val="count.accountMoney / 100" :decimals="2" :duration="1" />
        </div>
      </div>
    </el-card>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;" height="100px">
      <el-table-column prop="code" label="单号" width="120" />
      <el-table-column prop="friendEntName" label="经销商" min-width="160" show-overflow-tooltip />
      <el-table-column prop="relationFormCode" label="关联单据号" min-width="120" show-overflow-tooltip />
      <el-table-column label="方向" width="60">
        <template slot-scope="scope">{{scope.row.inOutType === "in" ? '收入' : '支出'}}</template>
      </el-table-column>
      <el-table-column label="收支类别" width="80">
        <template slot-scope="scope">{{relationFormTypes[scope.row.relationFormType]}}</template>
      </el-table-column>
      <el-table-column prop="projectName" label="记账类型" width="80" />
      <el-table-column label="支付方式" width="80" :formatter="r => {return paymentMethods[r.payType];}" />
      <el-table-column label="支付渠道" width="80" :formatter="r => {return payChannels[r.payChannel];}" />
      <el-table-column label="交易类型" width="80" :formatter="r => {return payWays[r.tradeType]?payWays[r.tradeType]:'';}" />
      <el-table-column label="ERP结算方式" width="100" :formatter="r => {return erpPayChannels[r.payChannel];}" />
      <el-table-column label="收/支金额" align="right" width="120">
        <template slot-scope="scope">
          <b :class="scope.row.inOutType === 'out' ? 'fc-e' : 'fc-s'">{{$price(scope.row.payMoney)}}</b>
        </template>
      </el-table-column>
      <el-table-column label="使用余额" align="right" width="120">
        <template slot-scope="scope">
          <b :class="scope.row.inOutType === 'out' ? 'fc-e' : 'fc-s'">{{$price(scope.row.accountMoney)}}</b>
        </template>
      </el-table-column>
      <el-table-column label="手续费" align="right" width="140">
        <template slot-scope="scope">
          <b>{{$price(scope.row.fee)}}</b>
          <div class="fs-mini fc-g lh-150" v-if="scope.row.fee > 0">
            经销商: {{$price(scope.row.payFee)}}
            <br />
            供应商: {{$price(scope.row.fee - scope.row.payFee)}}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="payTime" label="支付日期" width="90" :formatter="r => {return new Date(r.payTime).format('yyyy/MM/dd');}" />
      <el-table-column prop="checkinTime" label="审核日期" width="90" :formatter="r => {return r.checkinTime?new Date(r.checkinTime).format('yyyy/MM/dd'):'';}" />        
      <el-table-column prop="info" label="说明" show-overflow-tooltip min-width="200" />
      <el-table-column label="状态" width="80" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="50px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="audit(scope.row)" v-if="scope.row.status === 0">审批</el-button>
          <el-button size="mini" type="text" @click="detail(scope.row)" v-else>查看</el-button>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <eForm ref="form" />
    <cashout-form ref="cashout" />
    <cashin-form ref="cashin" />
    <importForm ref="importForm" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";
import CashoutForm from "../cash-out/form";
import CashinForm from "../cash-in/form";
import Counter from "vue-count-to";
import request from "@/utils/request";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import importForm from "./import-detail";
export default {
  components: { eForm, CashoutForm, Counter, CashinForm, importForm },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      delLoading: false,
      count: null,
      paymentMethods: ["在线支付", "线下支付"],
      payChannels: { thirdPay: "扫码支付", unionPay: "银联支付" },
      erpPayChannels: { thirdPay: "全付通", unionPay: "通联" },
      payWays: { "pay.weixin.jspay": "微信", "pay.alipay.jspay": "支付宝" },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      relationFormTypes: ["销售", "其他", "提现", "充值", "费用","质保金"],
      status: [
        {
          type: "info",
          label: "待审批",
        },
        {
          type: "success",
          label: "已通过",
        },
        {
          type: "danger",
          label: "未通过",
        },
        {
          type: "primary",
          label: "已支付",
        },
      ],
      query: {
        friendEntId: null,
        dateRange: null,
        inOutType: null,
        relationFormType: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/payForm";
      let query = JSON.parse(JSON.stringify(this.query));      
      if (query.dateRange && query.dateRange.length === 2) {
        if(query.dateType===1){
          query.beginCheckTime = query.dateRange[0];
          query.endCheckTime = query.dateRange[1];
        }else{
          query.beginTime = query.dateRange[0];
          query.endTime = query.dateRange[1];
        }
      }
      delete query.dateRange;
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: "code,desc" },
        query
      );
      this.loadSums();
      return true;
    },
    loadSums() {
      request({
        url: "api/payForm/sum",
        method: "get",
        params: this.params,
      }).then((res) => {
        this.count = res;
      });
    },
    addCashout() {
      this.$refs.cashout && this.$refs.cashout.resetForm();
    },
    addCashin() {
      this.$refs.cashin && this.$refs.cashin.resetForm();
    },
    detail(data) {
      this.$refs.form && this.$refs.form.resetForm(data);
    },
    audit(data) {
      this.$refs.form && this.$refs.form.resetForm(data, true);
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/payForm/download", this.params)
        .then((result) => {
          downloadFile(result, "收支明细", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    upload() {
      this.$refs.importForm && this.$refs.importForm.resetForm();
    },
  },
};
</script>