<template>
  <div>
    <template v-if="form">
      <template v-if="editable && !dependRetailFormCode">
        <el-form ref="form" :model="form" :rules="rules" label-width="84px" label-position="right" label-suffix=":">
          <div class="h s">
            <el-form-item prop="sellerId" label="供应商" v-if="mode === 'window'">
              <quick-select v-model="form.sellerId" filterable url="api/purchase/seller" value-field="belongTo" display-field="belongToName" auto-select-first-option @change="handleSellerChange" style="width: 180px;" />
            </el-form-item>
            <el-form-item prop="buyerErpId" label="下单账户" v-if="mode === 'window'">
              <quick-select v-model="form.buyerErpId" :label.sync="form.buyerName" filterable url="api/distributorErps/list" :params="{enable:true}" placeholder="下单账户" value-field="erpId" display-field="name" style="width: 180px;" autoSelectFirstOption @change="handleBuyerChange" />
            </el-form-item>
            <el-form-item prop="purchaseTime" label="采购日期" :label-width="mode === 'window' ? '100px' : '84px'">
              <el-date-picker v-model="form.purchaseTime" :clearable="false" value-format="timestamp" style="width: 180px;" :readonly="true" />
              <!-- {{new Date(form.purchaseTime).format()}} -->
            </el-form-item>
            <!-- <el-form-item label="交货日期" label-width="100px">
              <el-date-picker v-model="defaultShipDate" :clearable="false" value-format="timestamp" style="width: 180px;" :picker-options="shipDatePickerOptions" />
            </el-form-item>-->
          </div>

          <el-form-item style="margin-bottom: 8px !important;" v-if="form.sellerId">
            <div class="h e">
              <sku-selector simple-query request-url="api/purchaseGoods" :showPrice="true" :request-params="{entId: form.sellerId}" :purchase-limit="!purchaseLimit.allow" @submit="handleGoodsAdd" v-if="purchaseLimit">
                <el-alert slot="tip" type="error" title="有部分商品由于被限购而无法选取进行采购" :closable="false" style="margin-bottom: 10px;" v-if="!purchaseLimit.allow" />
              </sku-selector>
              <retail-order :supplier="form.sellerId" :exclude-keys="retailExcludeKeys" @submit="handleGoodsAdd" style="margin-left: 10px;" />
              <retail-single-goods :supplier="form.sellerId" :exclude-keys="retailExcludeKeys" @submit="handleGoodsAdd" style="margin-left: 10px;" />
              <group-sale-selector :supplier="form.sellerId" @submit="handleGroupSaleAdd" style="margin-left: 10px;" />
              <el-button size="small" type="primary" @click="promotionVisible = true" style="margin-left: 10px;">促销活动</el-button>
            </div>
            <div class="gap-1x" v-if="promotionGoodsRemoved">
              <el-alert type="error" style="line-height: 1;" :closable="false" title="原订单部分商品可能发生变更已自动移除，如需继续采购，请手动再次添加" />
            </div>
            <!-- <div style="color:#606266;font-size:10px;" v-if="purchaseLimit">
              <template v-if="purchaseLimit.remark">{{purchaseLimit.remark}}</template>
              <template v-else-if="purchaseLimit.limit > 0">提示：本月采购次数还剩下{{purchaseLimit.limit - purchaseLimit.used}}次</template>
            </div>-->
          </el-form-item>
          <el-form-item label="采购商品" prop="items">
            <el-table :data="form.items" empty-text="还没有添加要采购的商品" class="table-outdent" :maxHeight="600">
              <el-table-column width="30">
                <template slot-scope="scope">
                  <el-tooltip content="促销活动商品" v-if="scope.row.fromPromotion">
                    <i class="fc-e fas fa-bookmark"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column prop="code" label="商品编码" width="135" />
              <el-table-column prop="erpCode" label="ERP编码" width="135" />
              <el-table-column label="商品" min-width="240">
                <div style="line-height: 1.5" slot-scope="scope">
                  <div>{{scope.row.goodsName}}</div>
                  <div class="fc-g">{{$goodsSpecConvert(scope.row.goodsSpec)}}</div>
                  <div class="fc-e" v-if="scope.row.wholePromotionId">整单优惠：{{scope.row.wholePromotionName}}</div>
                  <el-dropdown szie="mini" placement="bottom-start" @command="handleGoodsPromotionSet(scope.row, $event)" style="width: 100%;" v-if="scope.row._promotions && scope.row._promotions.length">
                    <div style="line-height: 1.5" class="h c fc-e fs-mini">
                      <div class="flex">商品促销：{{getGoodsPromotionInfo(scope.row)}}</div>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="p in scope.row._promotions" :key="p.id" :command="p.id" style="min-width: 240px;">
                        <div class="h c fs-mini" :class="p.id === scope.row.promotionId ? 'fc-p' : ''">
                          <div class="flex">商品促销：{{p._label}}&emsp;&emsp;</div>
                          <div class="fa fa-check fc-p" v-if="p.id === scope.row.promotionId"></div>
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item divided :command="null">不参与商品促销活动</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </el-table-column>
              <el-table-column label="期待发货时间" width="155">
                <template slot-scope="scope">
                  <el-date-picker v-model="scope.row.shipDate" :clearable="false" size="mini" value-format="timestamp" :picker-options="genGoodsShipDatePickerOptions(scope.row)" style="width: 100%" v-if="scope.row._minShipDate" />
                </template>
              </el-table-column>
              <el-table-column label="当前库存" width="95" v-if="mode === 'window'">
                <template slot-scope="scope">
                  <div v-if="scope.row._stockInfo.loading" class="fc-g">正在计算中…</div>
                  <div v-else-if="scope.row._stockInfo.error" class="h c">
                    <span class="fc-e">{{scope.row._stockInfo.error}}</span>
                    <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row)"></el-button>
                  </div>
                  <div v-else class="h c">
                    <b>{{calcStock(scope.row)}}</b>

                    <el-popover placement="bottom" trigger="click">
                      <div class="h c">
                        <span>库存数量：{{scope.row._stockInfo.values.warehouseCount}}</span>
                        <el-divider direction="vertical" />
                        <span>待发货数量：{{scope.row._stockInfo.values.saleCount}}</span>
                        <el-divider direction="vertical" />
                        <span>在途数量：{{scope.row._stockInfo.values.purchaseCount}}</span>
                      </div>
                      <div v-if="scope.row._stockInfo.values.detailInfo && scope.row._stockInfo.values.detailInfo!=null">
                        <el-table border :data="scope.row._stockInfo.values.detailInfo" row-key="id">
                          <el-table-column prop="warehouseName" label="库房名称" width="150" show-overflow-tooltip>
                            <template slot-scope="scope">{{scope.row.warehouseName!=null ? scope.row.warehouseName:'--'}}</template>
                          </el-table-column>
                          <el-table-column prop="count" label="库存数量" width="125" show-overflow-tooltip>
                            <template slot-scope="scope">{{scope.row.count!=null ? scope.row.count:'--'}}</template>
                          </el-table-column>
                          <el-table-column prop="occupy" label="占用数量" width="125" show-overflow-tooltip>
                            <template slot-scope="scope">{{scope.row.occupy!=null ? scope.row.occupy:'--'}}</template>
                          </el-table-column>
                        </el-table>
                      </div>
                      <el-button size="mini" type="text" slot="reference" style="padding: 0 0 3px 5px !important;">明细</el-button>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="单价" width="90" align="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
                <template slot-scope="scope">
                  <div v-if="scope.row._price.loading" class="fc-g">获取中…</div>
                  <div v-else-if="scope.row._price.error" class="h c">
                    <span class="fc-e">{{scope.row._price.error}}</span>
                    <a href="javascript:void(0)" @click="loadGoodsPrice(scope.row)">
                      &nbsp;
                      <i class="el-icon-refresh"></i>
                    </a>
                  </div>
                  <div style="line-height: 1.5" class="v e" v-else>
                    <b class="fc-e">{{$price(scope.row._price.value - scope.row.giftDiscountPrice)}}</b>
                    <span class="fc-g through" v-if="scope.row._price.oldValue > scope.row._price.value - scope.row.giftDiscountPrice">{{$price(scope.row._price.oldValue)}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="数量" width="100" align="center">
                <template slot-scope="scope">
                  <el-input-number :ref="`item_quantity_${scope.row._id}`" v-model="scope.row.count" :min="scope.row.minSalesQuantity || 0.01" :max="9999" :step="1" :precision="2" size="mini" controls-position="right" :disabled="scope.row._price.loading" @change="$checkNaN(scope.row, 'count', scope.row.minSalesQuantity || 0.01, loadGoodsPrice)" style="width: 100%;" />
                </template>
              </el-table-column>
              <el-table-column label="金额" width="100" align="center" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
                <template slot-scope="scope">
                  <div v-if="scope.row._price.loading" class="fc-g">获取中…</div>
                  <div v-else-if="scope.row._price.error" class="h c">
                    <span class="fc-e">{{scope.row._price.error}}</span>
                  </div>
                  <div style="line-height: 1.5" class="v e" v-else>
                    <b class="fc-e">{{$price((scope.row._price.value - scope.row.giftDiscountPrice)*scope.row.count)}}</b>
                    <span class="fc-g through" v-if="scope.row._price.oldValue > scope.row._price.value - scope.row.giftDiscountPrice">{{$price(scope.row._price.oldValue*scope.row.count)}}</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="备注" width="160">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.info" size="mini" :maxlength="100" />
                </template>
              </el-table-column>
              <el-table-column width="50">
                <div class="row-commands" slot-scope="scope">
                  <el-tooltip placement="top">
                    <div slot="content" style="max-width: 800px;">
                      <template v-if="scope.row.source && scope.row.source.length">
                        <b>已关联零售单：</b>
                        <template v-for="(s, i) in scope.row.source">
                          <div :key="s">零售单号：{{scope.row.retailFormCode[i]}}，门店：{{scope.row.shop[i]}}，客户姓名：{{scope.row.customer[i]}}</div>
                        </template>
                      </template>
                      <template v-else>没有关联零售单，点击进行关联。</template>
                    </div>
                    <i class="fa fa-link" @click="handleGoodsLinkRetail(scope.row)"></i>
                  </el-tooltip>
                  <el-button type="text" size="mini" icon="el-icon-delete" class="danger" @click="handleGoodsRemove(scope.row)" />
                </div>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="赠品列表" v-if="form._gifts && form._gifts.length" style="margin-top: -10px;">
            <el-table :data="form._gifts" :show-header="false" row-class-name="common-gift-goods-row" class="table-outdent">
              <el-table-column width="30" />
              <el-table-column prop="current.code" label="商品编码" width="135" />
              <el-table-column prop="current.erpCode" label="ERP编码" width="135" />
              <el-table-column label="商品" min-width="240">
                <template slot-scope="scope">
                  <div style="line-height: 1.5" v-if="scope.row.goodsList.length === 1">
                    <div>{{scope.row.current.goodsName}}</div>
                    <div class="fc-g">{{$goodsSpecConvert(scope.row.current.goodsSpec)}}</div>
                    <div class="fc-e">{{scope.row.isOrderPromotion ? '整单优惠' : '商品促销'}}：{{scope.row.promotionName}}</div>
                  </div>
                  <el-dropdown placement="top-start" @command="handleGiftSet(scope.row, $event)" style="width: 100%;" v-else>
                    <div class="h c fs-mini">
                      <div style="line-height: 1.5" class="flex">
                        <div>{{scope.row.current.goodsName}}</div>
                        <div class="fc-g">{{$goodsSpecConvert(scope.row.current.goodsSpec)}}</div>
                        <div class="fc-e">{{scope.row.isOrderPromotion ? '整单优惠' : '商品促销'}}：{{scope.row.promotionName}}</div>
                      </div>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <div style="overflow:auto;height:500px">
                        <el-dropdown-item v-for="g in scope.row.goodsList" :key="g.id" :command="g.id" style="min-width: 240px;">
                          <div class="fs-mini h c" :class="g.id === scope.row.giftId ? 'fc-p' : ''">
                            <div class="flex">{{g.name}}（{{$goodsSpecConvert(g.specs)}}）&nbsp;×&nbsp;{{g.quantity}}&emsp;&emsp;</div>
                            <div class="fa fa-check fc-p" v-if="g.id === scope.row.giftId"></div>
                          </div>
                        </el-dropdown-item>
                      </div>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
              <el-table-column label="期待发货时间" width="155">
                <template slot-scope="scope">
                  <el-date-picker v-model="scope.row.current.shipDate" :clearable="false" size="mini" value-format="timestamp" style="width: 100%" :picker-options="genGoodsShipDatePickerOptions(scope.row)" v-if="scope.row._minShipDate" />
                </template>
              </el-table-column>
              <el-table-column label="当前库存" width="95" v-if="mode === 'window'">
                <!-- <template slot-scope="scope"> -->
                <!-- <div v-if="scope.row.current._stockInfo.loading" class="fc-g">正在计算中…</div>
                  <div v-else-if="scope.row.current._stockInfo.error" class="h c">
                    <span class="fc-e">{{scope.row.current._stockInfo.error}}</span>
                    <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row.current)"></el-button>
                  </div>
                  <div v-else class="h c">                    
                    <b>{{calcStock(scope.row)}}</b>

                    <el-popover placement="bottom" trigger="click">
                      <div class="h c">
                        <span>库存数量：{{scope.row._stockInfo.values.warehouseCount}}</span>
                        <el-divider direction="vertical" />
                        <span>待发货数量：{{scope.row._stockInfo.values.saleCount}}</span>
                        <el-divider direction="vertical" />
                        <span>在途数量：{{scope.row._stockInfo.values.purchaseCount}}</span>
                      </div>
                      <div v-if="scope.row._stockInfo.values.detailInfo && scope.row._stockInfo.values.detailInfo!=null">
                        <el-table border :data="scope.row._stockInfo.values.detailInfo" row-key="id">
                          <el-table-column prop="warehouseName" label="库房名称" width="150" show-overflow-tooltip>
                            <template slot-scope="scope">{{scope.row.warehouseName!=null ? scope.row.warehouseName:'--'}}</template>
                          </el-table-column>
                          <el-table-column prop="count" label="库存数量" width="125" show-overflow-tooltip>
                            <template slot-scope="scope">{{scope.row.count!=null ? scope.row.count:'--'}}</template>
                          </el-table-column>
                          <el-table-column prop="occupy" label="占用数量" width="125" show-overflow-tooltip>
                            <template slot-scope="scope">{{scope.row.occupy!=null ? scope.row.occupy:'--'}}</template>
                          </el-table-column>
                        </el-table>
                      </div>
                      <el-button size="mini" type="text" slot="reference" style="padding: 0 0 3px 5px !important;">明细</el-button>
                    </el-popover>
                </div>-->
                <!-- </template> -->
              </el-table-column>
              <el-table-column label="单价" width="90" align="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
                <template slot-scope="scope">
                  <b class="fc-e">{{$price(scope.row.current.realPrice)}}</b>
                </template>
              </el-table-column>
              <el-table-column prop="current.count" label="数量" width="100" align="center" />
              <el-table-column width="100" align="center" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])" />
              <el-table-column label="备注" width="160">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.info" size="mini" :maxlength="100" />
                </template>
              </el-table-column>
              <el-table-column width="50" align="right">
                <template slot-scope="scope">
                  <el-tooltip placement="top">
                    <div slot="content" style="max-width: 800px;">
                      <template v-if="scope.row.current.source && scope.row.current.source.length">
                        <b>已关联零售单：</b>
                        <template v-for="(s, i) in scope.row.current.source">
                          <div :key="s">零售单号：{{scope.row.current.retailFormCode[i]}}，门店：{{scope.row.current.shop[i]}}，客户姓名：{{scope.row.current.customer[i]}}</div>
                        </template>
                      </template>
                      <template v-else>没有关联零售单，点击进行关联。</template>
                    </div>
                    <i class="fa fa-link" @click="handleGoodsLinkRetail(scope.row.current)"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="组合销售" v-if="form._groupSales && form._groupSales.length" style="margin-top: -10px;">
            <template v-for="g in form._groupSales">
              <div class="group-sale" :key="g.id">
                <div class="h c">
                  <div class="flex padding-0-10 fs-small">
                    <b>{{g.groupSaleName}}</b>
                    <span class="padding-0-10" v-if="g.discountAmount > 0">(优惠: {{$price(g.discountAmount)}})</span>
                  </div>
                  <div class="padding-10" style="width: 100px;">
                    <el-input-number size="mini" v-model="g.times" :min="g.minTimes" :max="g.maxTimes || 9999" :step="1" :precision="0" :controls="false" @change="handleGroupSaleTimesChange(g)" style="width: 80px;" />
                  </div>
                  <div style="width: 260px;" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])"></div>
                  <div style="width: 160px;" v-else></div>
                  <div class="padding-10" style="width: 40px;">
                    <div class="row-commands">
                      <el-button type="text" size="mini" icon="el-icon-delete" class="danger" @click="handleGroupSaleRemove(g)" />
                    </div>
                  </div>
                </div>
                <el-table :data="g.items" :show-header="false">
                  <el-table-column width="30" />
                  <el-table-column prop="code" label="商品编码" width="135" />
                  <el-table-column prop="erpCode" label="ERP编码" width="135" />
                  <el-table-column label="商品" min-width="240">
                    <div style="line-height: 1.5" slot-scope="scope">
                      <div>{{scope.row.goodsName}}</div>
                      <div class="fc-g">{{$goodsSpecConvert(scope.row.goodsSpec)}}</div>
                      <div class="fc-e" v-if="scope.row.wholePromotionId">整单优惠：{{scope.row.wholePromotionName}}</div>
                    </div>
                  </el-table-column>
                  <el-table-column label="期待发货时间" width="155">
                    <template slot-scope="scope">
                      <el-date-picker v-model="scope.row.shipDate" :clearable="false" size="mini" value-format="timestamp" :picker-options="genGoodsShipDatePickerOptions(scope.row)" style="width: 100%" v-if="scope.row._minShipDate" />
                    </template>
                  </el-table-column>
                  <el-table-column label="当前库存" width="95" v-if="mode === 'window'">
                    <template slot-scope="scope">
                      <div v-if="scope.row._stockInfo.loading" class="fc-g">正在计算中…</div>
                      <div v-else-if="scope.row._stockInfo.error" class="h c">
                        <span class="fc-e">{{scope.row._stockInfo.error}}</span>
                        <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row)"></el-button>
                      </div>
                      <div v-else class="h c">
                        <b>{{calcStock(scope.row)}}</b>

                        <el-popover placement="bottom" trigger="click">
                          <div class="h c">
                            <span>库存数量：{{scope.row._stockInfo.values.warehouseCount}}</span>
                            <el-divider direction="vertical" />
                            <span>待发货数量：{{scope.row._stockInfo.values.saleCount}}</span>
                            <el-divider direction="vertical" />
                            <span>在途数量：{{scope.row._stockInfo.values.purchaseCount}}</span>
                          </div>
                          <div v-if="scope.row._stockInfo.values.detailInfo && scope.row._stockInfo.values.detailInfo!=null">
                            <el-table border :data="scope.row._stockInfo.values.detailInfo" row-key="id">
                              <el-table-column prop="warehouseName" label="库房名称" width="150" show-overflow-tooltip>
                                <template slot-scope="scope">{{scope.row.warehouseName!=null ? scope.row.warehouseName:'--'}}</template>
                              </el-table-column>
                              <el-table-column prop="count" label="库存数量" width="125" show-overflow-tooltip>
                                <template slot-scope="scope">{{scope.row.count!=null ? scope.row.count:'--'}}</template>
                              </el-table-column>
                              <el-table-column prop="occupy" label="占用数量" width="125" show-overflow-tooltip>
                                <template slot-scope="scope">{{scope.row.occupy!=null ? scope.row.occupy:'--'}}</template>
                              </el-table-column>
                            </el-table>
                          </div>
                          <el-button size="mini" type="text" slot="reference" style="padding: 0 0 3px 5px !important;">明细</el-button>
                        </el-popover>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="单价" width="90" align="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
                    <template slot-scope="scope">
                      <div v-if="scope.row._price.loading" class="fc-g">正在获取中…</div>
                      <div v-else-if="scope.row._price.error" class="h c">
                        <span class="fc-e">{{scope.row._price.error}}</span>
                        <a href="javascript:void(0)" @click="loadGoodsPrice(scope.row)">
                          &nbsp;
                          <i class="el-icon-refresh"></i>
                        </a>
                      </div>
                      <div class="v e" style="line-height: 1.5" v-else>
                        <b class="fc-e">{{$price(scope.row._price.value - scope.row.giftDiscountPrice)}}</b>
                        <span class="fc-g through" v-if="scope.row._price.oldValue > scope.row._price.value - scope.row.giftDiscountPrice">{{$price(scope.row._price.oldValue)}}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="count" label="数量" width="100" align="center" />
                  <el-table-column width="100" align="center" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])" />
                  <el-table-column label="备注" width="160">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.info" size="mini" :maxlength="100" />
                    </template>
                  </el-table-column>
                  <el-table-column width="40" align="right">
                    <template slot-scope="scope">
                      <el-tooltip placement="top">
                        <div slot="content" style="max-width: 800px;">
                          <template v-if="scope.row.source && scope.row.source.length">
                            <b>已关联零售单：</b>
                            <template v-for="(s, i) in scope.row.source">
                              <div :key="s">零售单号：{{scope.row.retailFormCode[i]}}，门店：{{scope.row.shop[i]}}，客户姓名：{{scope.row.customer[i]}}</div>
                            </template>
                          </template>
                          <template v-else>没有关联零售单，点击进行关联。</template>
                        </div>
                        <i class="fa fa-link" @click="handleGoodsLinkRetail(scope.row)"></i>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </el-form-item>
          <div class="h">
            <div class="flex">
              <el-form-item label="整单优惠">
                <quick-select v-model="form.promotionId" :options="promotions" display-field="_label" :disabled="promotionLoading || !promotions || !promotions.length" clearable empty-text="没有可以参与的整单优惠活动" :placeholder="promotions && promotions.length ? '请选择整单优惠活动' : '没有可以参与的整单优惠活动'" @change="loadPromotions" style="width: 460px;" />
              </el-form-item>
              <el-form-item prop="payType" label="支付方式">
                <el-radio-group v-model="form.payType">
                  <el-radio-button :label="0" v-if="enablePayType !== 'offline'">线上支付</el-radio-button>
                  <el-radio-button :label="1" v-if="enablePayType !== 'online'">线下支付</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="v e">
              <div class="h c">
                <form-info-item label="商品数量" style="margin-right: 50px;">
                  <b>{{totalQuantity}}</b>
                </form-info-item>
                <template v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
                  <template v-if="typeof totalMoney === 'number'">
                    <template v-if="orderDiscount > 0">
                      <form-info-item label="商品金额" style="margin-right: 10px;">
                        <b>{{$price(totalMoney)}}</b>
                      </form-info-item>
                      <form-info-item label="优惠金额" style="margin-right: 10px;">
                        <b class="fc-e">{{$price(orderDiscount)}}</b>
                      </form-info-item>
                    </template>
                    <form-info-item label="订单金额">
                      <b>{{$price(totalMoney - orderDiscount)}}</b>
                    </form-info-item>
                  </template>
                  <form-info-item label="订单金额" v-else>
                    <b>{{totalMoney}}</b>
                  </form-info-item>
                </template>
              </div>
              <form-info-item>
                <div class="h c" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
                  <el-checkbox v-model="form.useRebate">使用返利抵扣{{canUsedRebateStr}}</el-checkbox>
                  <el-popover placement="top" width="360" trigger="hover">
                    <div>
                      返利抵扣金额在订单生成后由系统自动计算，当订单的商品、数量、参加的促销活动等进行更改后，返利抵扣金额也可能发生变动。
                      <br />
                      <span class="fc-e">当前显示的返利抵扣可抵金额仅供参考，请以提交订单后为准。</span>
                    </div>
                    <i class="el-icon-question" style="margin-left: 10px;" slot="reference"></i>
                  </el-popover>
                </div>
              </form-info-item>
            </div>
          </div>
          <div class="h s">
            <el-form-item label="收货人" prop="receiverName">
              <el-input v-model="form.receiverName" :maxlength="50" style="width: 180px;" />
            </el-form-item>
            <el-form-item prop="receiverPhone" label="联系电话" label-width="100px">
              <el-input v-model="form.receiverPhone" :maxlength="20" style="width: 260px;" />
            </el-form-item>
            <el-form-item label-width="10px">
              <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/distributor/contact" empty-text="还没有常用收货人信息，可在“基础数据/收货信息”中添加。" @change="handleContactChange">
                <el-table-column prop="name" label="姓名" width="100" />
                <el-table-column prop="mobilePhone" label="手机号码" width="150" />
                <el-table-column prop="officePhone" label="办公室电话" width="150" />
              </datagrid-picker>
            </el-form-item>
          </div>
          <div class="h s">
            <el-form-item label="收货地址" prop="recProvinceCode">
              <quick-select v-model="form.recProvinceCode" :label.sync="form.recProvince" url="api/regionalDict/province" value-field="code" placeholder="选择省份" filterable style="width: 180px;" @change="form.recCityCode = null; form.recDistrictCode = null;" />
            </el-form-item>
            <el-form-item label-width="10px" prop="recCityCode" style="width: 180px;" v-if="form.recProvinceCode">
              <quick-select v-model="form.recCityCode" :label.sync="form.recCity" :url="`api/regionalDict/parent/${form.recProvinceCode}`" value-field="code" placeholder="选择城市" filterable style="width: 100%;" @change="form.recDistrictCode = null" />
            </el-form-item>
            <el-form-item label-width="10px" style="width: 180px;" v-else>
              <el-input disabled placeholder="请先选择省份" />
            </el-form-item>
            <el-form-item label-width="10px" prop="recDistrictCode" style="width: 180px;" v-if="form.recCityCode">
              <quick-select v-model="form.recDistrictCode" :label.sync="form.recDistrict" :url="`api/regionalDict/parent/${form.recCityCode}`" value-field="code" placeholder="选择地区" filterable style="width: 100%;" />
            </el-form-item>
            <el-form-item label-width="10px" style="width: 180px;" v-else>
              <el-input disabled placeholder="请先选择城市" />
            </el-form-item>
            <el-form-item label-width="10px" prop="recAddress" class="flex">
              <el-input v-model="form.recAddress" :maxlength="100" class="flex" placeholder="请输入详细地址" />
            </el-form-item>
            <el-form-item label-width="10px">
              <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/distributor/address" empty-text="还没有常用收货地址信息，可在“基础数据/收货信息”中添加。" @change="handleAddressChange">
                <el-table-column prop="provinceName" label="省" width="100" />
                <el-table-column prop="cityName" label="市" width="150" />
                <el-table-column prop="districtName" label="区" width="150" />
                <el-table-column prop="address" label="详细地址" width="300" />
              </datagrid-picker>
            </el-form-item>
          </div>
          <el-form-item prop="info" label="摘要">
            <el-input type="textarea" v-model="form.info" :maxlength="200" placeholder="有其他要求或需要说明的情况，请在备注中说明" resize="none" :rows="3" />
          </el-form-item>
        </el-form>
        <div class="dialog-footer h r">
          <el-button type="text" @click="handleClose" v-if="mode === 'window'">取消</el-button>
          <el-button :loading="saving" :disabled="submiting || promotionLoading" type="primary" @click="doSave" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_ADD_EDIT'])">保存</el-button>
          <template v-if="mode === 'window'">
            <el-button :loading="submiting" :disabled="saving || submiting || promotionLoading" type="danger" @click="doSubmit" v-if="form.payType === 1 && checkPermission(['PURCHASE_ALL','PURCHASE_SAVE_SUBMIT'])">保存并提交</el-button>
            <el-button :loading="saving" type="danger" @click="doPay" v-if="form.payType === 0 && checkPermission(['PURCHASE_ALL','PURCHASE_PAY'])">立即支付</el-button>
          </template>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" label-width="68px" label-suffix=":" label-position="right">
          <div class="h sb">
            <div>
              <form-info-item label="采购单号">{{form.formCode}}</form-info-item>
              <form-info-item label="ERP单号" v-if="form.erpFormCode && form.erpFormCode !== form.formCode">{{form.erpFormCode}}</form-info-item>
              <form-info-item label="采购时间">{{new Date(form.purchaseTime).format()}}</form-info-item>
            </div>
            <div>
              <form-info-item label="供应商">{{form.seller.name}}</form-info-item>
              <form-info-item label="收货人">{{form.receiverName}}（{{form.receiverPhone}}）</form-info-item>
              <form-info-item label="收货地址">{{form.recProvince}}{{form.recCity}}{{form.recDistrict}}{{form.recAddress}}</form-info-item>
            </div>
          </div>
          <el-table border :data="form.items" empty-text="当前采购单没有任何商品" style="margin: 8px 0;">
            <el-table-column prop="code" label="商品编码" min-width="150" />
            <el-table-column prop="erpCode" label="ERP编码" min-width="150" />
            <el-table-column label="商品" min-width="240">
              <div class="h" style="line-height: 1.5" slot-scope="scope">
                <template v-if="scope.row.isGift">
                  <el-tag size="mini" type="danger">赠</el-tag>&emsp;
                </template>
                <template v-if="scope.row.fromPromotion">
                  <el-tag size="mini" type="danger">促</el-tag>&emsp;
                </template>
                <div>
                  <div>{{scope.row.goodsName}}</div>
                  <div class="fc-g">{{$goodsSpecConvert(scope.row.goodsSpec)}}</div>
                  <div class="fc-e" v-if="scope.row.promotionJoinType === 'groupSale'">组合销售：{{scope.row.groupSaleName}}</div>
                  <template v-else>
                    <div class="fc-e" v-if="scope.row.promotionId">商品促销：{{scope.row.promotionName}}</div>
                    <div class="fc-e" v-if="scope.row.wholePromotionId">整单优惠：{{scope.row.wholePromotionName}}</div>
                  </template>
                </div>
              </div>
            </el-table-column>
            <el-table-column label="期待发货时间" width="100" :formatter="r => { return new Date(r.shipDate).format('yyyy/MM/dd'); }" />
            <el-table-column label="当前库存" width="90" v-if="mode === 'window'">
              <template slot-scope="scope">
                <div v-if="scope.row._stockInfo.loading" class="fc-g">正在计算中…</div>
                <div v-else-if="scope.row._stockInfo.error" class="h c">
                  <span class="fc-e">{{scope.row._stockInfo.error}}</span>
                  <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row)"></el-button>
                </div>
                <div v-else class="h c">
                  <b>{{calcStock(scope.row)}}</b>

                  <el-popover placement="bottom" trigger="click">
                    <div class="h c">
                      <span>库存数量：{{scope.row._stockInfo.values.warehouseCount}}</span>
                      <el-divider direction="vertical" />
                      <span>待发货数量：{{scope.row._stockInfo.values.saleCount}}</span>
                      <el-divider direction="vertical" />
                      <span>在途数量：{{scope.row._stockInfo.values.purchaseCount}}</span>
                    </div>
                    <div v-if="scope.row._stockInfo.values.detailInfo && scope.row._stockInfo.values.detailInfo!=null">
                      <el-table border :data="scope.row._stockInfo.values.detailInfo" row-key="id">
                        <el-table-column prop="warehouseName" label="库房名称" width="150" show-overflow-tooltip>
                          <template slot-scope="scope">{{scope.row.warehouseName!=null ? scope.row.warehouseName:'--'}}</template>
                        </el-table-column>
                        <el-table-column prop="count" label="库存数量" width="125" show-overflow-tooltip>
                          <template slot-scope="scope">{{scope.row.count!=null ? scope.row.count:'--'}}</template>
                        </el-table-column>
                        <el-table-column prop="occupy" label="占用数量" width="125" show-overflow-tooltip>
                          <template slot-scope="scope">{{scope.row.occupy!=null ? scope.row.occupy:'--'}}</template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <el-button size="mini" type="text" slot="reference" style="padding: 0 0 3px 5px !important;">明细</el-button>
                  </el-popover>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="单价" width="240" align="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
              <div slot-scope="scope">
                <b class="fc-e">{{$price(scope.row._finalPrice, 4)}}</b>
                <div class="fc-g h e r lh-100 ta-c" v-if="scope.row.price > scope.row._finalPrice" v-html="scope.row._finalPrices"></div>
              </div>
            </el-table-column>
            <el-table-column prop="count" label="数量" align="center" width="70" />
            <el-table-column prop="sendCount" label="已发货" align="center" width="70" />
            <el-table-column prop="receivedCount" label="已收货" align="center" width="70" />
            <el-table-column prop="cancelCount" label="已取消" align="center" width="70" />
            <el-table-column prop="rebateAmount" label="返利抵扣" align="right" width="100" :formatter="$price" v-if="form.status !== 0 && form.status !== 1 && form.status !== 3 && checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])" />
            <el-table-column label="小计" width="100" align="right" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
              <template slot-scope="scope">
                <b class="fc-e">{{$price(scope.row._finalAmount)}}</b>
              </template>
            </el-table-column>
            <el-table-column label="对应订单" width="80" align="center">
              <template slot-scope="scope">
                <el-tooltip placement="top" v-if="scope.row.source && scope.row.source.length">
                  <div slot="content" style="max-width: 800px;">
                    <b>对应订单</b>
                    <template v-for="(s, i) in scope.row.source">
                      <div :key="s">零售单号：{{scope.row.retailFormCode[i]}}，门店：{{scope.row.shop[i]}}，客户姓名：{{scope.row.customer[i]}}</div>
                    </template>
                  </div>
                  <span>查看</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="shop" label="销售门店" min-width="120" />
            <el-table-column prop="customer" label="客户" min-width="120" />
            <el-table-column prop="info" label="备注" min-width="120" />
          </el-table>
          <div class="h s">
            <form-info-item class="flex" label="摘要">{{form.info}}</form-info-item>
            <form-info-item label="商品数量" style="margin-right: 50px;">
              <b>{{totalQuantity}}</b>
            </form-info-item>
            <form-info-item label="支付方式" style="margin-right: 50px;">{{payTypes[form.payType]}}</form-info-item>
            <template v-if="form.discountAmount > 0 && checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
              <form-info-item label="商品金额" style="margin-right: 50px;">
                <b>{{$price(form.totalMoney)}}</b>
              </form-info-item>
              <form-info-item label="优惠金额" style="margin-right: 50px;">
                <b class="fc-e">{{$price(form.discountAmount)}}</b>
              </form-info-item>
            </template>
            <form-info-item label="返利抵扣" style="margin-right: 50px;" v-if="form.useRebate && form.rebateAmount > 0 && checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
              <b class="fc-e">{{$price((form.rebateAmount || 0))}}(已确认：{{$price((form.realRebateAmount || 0))}})</b>
            </form-info-item>
            <form-info-item label="应付金额" content-style="padding-right: 10px;" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
              <b>{{$price((form.realTotalMoney || 0) - (form.realRebateAmount || 0))}}</b>
            </form-info-item>
          </div>
          <el-divider />
          <div class="h">
            <div style="width: 220px;" v-if="form.createBy">
              <form-info-item label="创建人">{{form.createBy}}</form-info-item>
              <form-info-item label="创建时间">{{new Date(form.createAt).format("yyyy/MM/dd hh:mm")}}</form-info-item>
            </div>
            <div style="width: 220px;" v-if="form.editBy">
              <form-info-item label="修改人">{{form.editBy}}</form-info-item>
              <form-info-item label="修改时间">{{new Date(form.editAt).format("yyyy/MM/dd hh:mm")}}</form-info-item>
            </div>
            <div style="width: 220px;" v-if="form.submitUser">
              <form-info-item label="提交人">{{form.submitUser}}</form-info-item>
              <form-info-item label="提交时间">{{new Date(form.submitTime).format("yyyy/MM/dd hh:mm")}}</form-info-item>
            </div>
            <div style="width: 220px;" v-if="form.checkerName">
              <form-info-item label="审批人">{{form.checkerName}}</form-info-item>
              <form-info-item label="审批时间">{{new Date(form.checkTime).format("yyyy/MM/dd hh:mm")}}</form-info-item>
            </div>
            <div style="width: 220px;" v-if="form.closeUser">
              <form-info-item label="关闭人">{{form.closeUser}}</form-info-item>
              <form-info-item label="关闭时间">{{new Date(form.closeTime).format("yyyy/MM/dd hh:mm")}}</form-info-item>
            </div>
          </div>
          <div class="h c">
            <form-info-item label="当前状态" style="width: 220px;">
              <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
            </form-info-item>
            <form-info-item label="付款情况" style="width: 220px;" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_PAY'])">
              <dot same :type="payStatus[form.payType][form.payStatus].type">{{payStatus[form.payType][form.payStatus].label}}</dot>
            </form-info-item>
            <div class="flex"></div>
            <el-button type="text" @click="handleClose" v-if="mode === 'window'">取消</el-button>
            <el-button type="primary" @click="doPrint" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">打印采购单</el-button>
          </div>
        </el-form>
      </template>
    </template>
    <template v-else-if="loading">
      <div v-loading="loading" style="height: 100px"></div>
    </template>

    <el-dialog :visible.sync="promotionVisible" title="促销活动" append-to-body width="1080px" v-if="editable">
      <promotion-selector v-if="form.sellerId" :supplier="form.sellerId" @submit="handleGoodsAdd" />
    </el-dialog>

    <retail-linker ref="retailLink">
      <div slot-scope="scope" style="margin-bottom: 10px;" v-if="scope.target">商品：{{scope.target.goodsName}} ({{$goodsSpecConvert(scope.target.goodsSpec)}})</div>
    </retail-linker>
  </div>
</template>

<script>
import {
  get,
  add,
  addAndSubmit,
  saveAndSubmit,
  edit,
  submit,
  getPurchaseGoodsPrice,
} from "@/api/purchaseOrder";
import { getSet } from "@/api/capitalPool";
import { getDefaultAddress, getDefaultContact } from "@/api/receiving";
import { getGoodsByShopId } from "@/api/warehouse";
import { mapGetters } from "vuex";
import request from "@/utils/request";
import skuSelector from "@/views/assembly/skuSelect";
import retailOrder from "./retail-order";
import retailSingleGoods from "./retail-single-goods";
import retailLinker from "./retail-linker";
import groupSaleSelector from "@/views/assembly/groupSaleSelect";
import promotionSelector from "@/views/assembly/promotionSelect";
import checkPermission from "@/utils/permission";
export default {
  components: {
    skuSelector,
    retailOrder,
    retailSingleGoods,
    retailLinker,
    groupSaleSelector,
    promotionSelector,
  },
  props: {
    title: String,
    mode: {
      type: String,
      default: "window",
    },
    dependRetailFormCode: Array,
  },
  data() {
    return {
      purchaseLimit: null,
      isAdd: false,
      loading: false,
      saving: false,
      submiting: false,
      form: null,
      minShipDate: null,
      defaultContact: null,
      defaultAddress: null,
      defaultShipDate: null,
      promotionGoodsRemoved: false,
      shipDatePickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < this.minShipDate; //this.$now.getDiffDays(7)
        },
      },
      maxRebate: 0,
      payTypes: ["线上支付", "线下支付"],
      enablePayType: "all",
      rebateRule: null,
      payStatus: [
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分付款成功",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "付款成功",
          },
        ],
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分已付款",
          },
          {
            type: "warning",
            label: "部分付款已确认",
          },
          {
            type: "success",
            label: "已付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "已完成",
          },
        ],
      ],
      status: [
        {
          type: "info",
          label: "编辑中",
        },
        {
          type: "warning",
          label: "已提交",
        },
        {
          type: "success",
          label: "已确认",
        },
        {
          type: "danger",
          label: "已拒绝",
        },
        {
          type: "warning",
          label: "待发货",
        },
        {
          type: "success",
          label: "部分已发货",
        },
        {
          type: "success",
          label: "已发货",
        },
        {
          type: "success",
          label: "部分已收货",
        },
        {
          type: "success",
          label: "已完成",
        },
        {
          type: "info",
          label: "已关闭",
        },
        {
          type: "info",
          label: "已取消",
        },
        {
          type: "info",
          label: "部分已关闭",
        },
      ],
      goodsQueryDefine: {
        name: "",
      },
      rules: {
        sellerId: [{ required: true, message: "请选择供应商" }],
        purchaseTime: [{ required: true, message: "请选择采购日期" }],
        payType: [{ required: true, message: "请选择支付方式" }],
        receiverName: [{ required: true, message: "请填写收货人" }],
        receiverPhone: [{ required: true, message: "请填写收货人联系电话" }],
        recProvinceCode: [{ required: true, message: "请选择省份" }],
        recCityCode: [{ required: true, message: "请选择城市" }],
        recDistrictCode: [{ required: true, message: "请选择地区" }],
        recAddress: [{ required: true, message: "请填写详细地址" }],
      },
      promotions: [],
      promotionLoading: false,
      promotionVisible: false,
      gifts: {},
    };
  },
  computed: {
    ...mapGetters(["user"]),
    retailExcludeKeys() {
      let keys = [];
      if (this.form && this.form.items && this.form.items) {
        this.form.items.forEach((o) => {
          if (o.source && o.source.length) {
            o.source.forEach((fid) => {
              keys.push(fid + "_" + o.goodsId);
            });
          }
        });
      }
      return keys;
    },
    totalMoney() {
      let c = 0,
        e = "";
      if (this.form) {
        for (let i = 0, l = this.form.items.length; i < l; i++) {
          let o = this.form.items[i];
          if (typeof o.count !== "number" || o.count < 0) o.count = 0;
          if (!o._price.loading && !o._price.error) {
            c += o._price.oldValue * o.count;
          } else {
            e = "正在获取中…";
            break;
          }
        }
        if (!e && this.form._groupSales && this.form._groupSales.length) {
          for (let i = 0, l = this.form._groupSales.length; i < l; i++) {
            let g = this.form._groupSales[i];
            for (let j = 0, jl = g.items.length; j < jl; j++) {
              let o = g.items[j];
              if (typeof o.count !== "number" || o.count < 1) o.count = 1;
              if (!o._price.loading && !o._price.error) {
                c += o._price.oldValue * o.count;
              } else {
                e = "正在获取中…";
                break;
              }
            }
            if (e) break;
          }
        }
        if (!e && this.form._gifts && this.form._gifts.length) {
          for (let o of this.form._gifts) {
            c += o.current.realPrice * o.current.count;
          }
        }
      }
      if (e) {
        return e;
      } else {
        return c;
      }
    },
    orderDiscount() {
      let dc = 0;
      if (this.form) {
        (this.form.items || []).forEach((o) => {
          dc += o.discountAmount + o.giftDiscountAmount;
        });

        (this.form._groupSales || []).forEach((g) => {
          let gsSum = 0;
          (g.items || []).forEach((o) => {
            gsSum += o.discountAmount + o.giftDiscountAmount;
          });
          g.discountAmount = gsSum;
          dc += gsSum;
        });
      }

      return dc;
    },
    canUsedRebateStr() {
      let r = 0,
        s = 0;
      if (this.rebateRule) {
        s = this.rebateRule.canUserBalance;
        if (s < 0) s = 0;
        if (!isNaN(this.totalMoney) && !isNaN(this.orderDiscount)) {
          let m = this.totalMoney - this.orderDiscount;
          if (m < 0) m = 0;
          if (m >= this.rebateRule.minMoney) {
            if (this.rebateRule.limitRaito > 0) {
              r = Math.round((m * this.rebateRule.limitRaito) / 10000);
            } else {
              r = m;
            }
            if (
              this.rebateRule.limitMoney > 0 &&
              r > this.rebateRule.limitMoney
            )
              r = this.rebateRule.limitMoney;
            if (r > s) r = s;
            if (r > m) r = m;
          }
        }
      }
      return `（可抵：${this.$price(r)} / 剩余：${this.$price(s)}）`;
    },
    editable() {
      return this.form && this.form.status === 0;
    },
    totalQuantity() {
      let q = 0;
      if (this.form && this.form.items && this.form.items) {
        this.form.items.forEach((o) => {
          q += (o.count || 0) * 10000;
        });
      }
      return Math.round(q) / 10000;
    },
  },
  methods: {
    checkPermission,
    handleClose() {
      this.$emit("close");
    },
    clear() {
      this.form = null;
      this.rebateRule = null;
      this.maxRebate = null;
    },
    genGoodsShipDatePickerOptions(row) {
      let minDate = row._minShipDate;
      return {
        disabledDate: (date) => {
          return date.getTime() < minDate;
        },
      };
    },
    convertForm() {
      return new Promise((resolve, reject) => {
        let form = null,
          hasNoSelectPromotion =
            this.promotions && this.promotions.length && !this.form.promotionId,
          err = null;
        if (this.form.items.length || this.form._groupSales.length) {
          form = JSON.parse(JSON.stringify(this.form));
          let hasPromotionsItem = false,
            hasSelect = false;
          for (let i = 0, l = form.items.length; i < l; i++) {
            let o = form.items[i];
            o.times = 1;
            if (!o._price || o._price.error || o._price.loading) {
              err = `商品：${o.goodsName}【${this.$goodsSpecConvert(
                o.goodsSpec
              )}】没有价格，无法采购`;
              break;
            }
            if (o.fromPromotion && !o.promotionId) {
              err = `促销商品：${o.goodsName}【${this.$goodsSpecConvert(
                o.goodsSpec
              )}】不满足参加促销活动条件或还未选择促销活动，不能采购，如确定不参与促销活动，请移除该促销商品，并重新从“选择商品”中选择该商品。`;
              break;
            }
            if (
              !this.form.promotionId &&
              o._promotions &&
              o._promotions.length
            ) {
              hasPromotionsItem = true;
              if (o.promotionId) {
                hasSelect = true;
              }
            }
            o.source = (o.source || []).join(",");
            o.retailFormCode = (o.retailFormCode || []).join(",");
            o.shop = (o.shop || []).join(",");
            o.customer = (o.customer || []).join(",");
            o.retailContractNo = (o.retailContractNo || []).join(",");
            delete o._price;
            delete o._stockInfo;
            delete o._promotions;
            delete o._finalPrice;
            delete o._finalPrices;
            delete o._finalAmount;
            delete o._id;
          }

          if (!hasNoSelectPromotion && hasPromotionsItem && !hasSelect) {
            hasNoSelectPromotion = true;
          }

          if (!err) {
            for (let i = 0, l = form._groupSales.length; i < l; i++) {
              let g = form._groupSales[i];
              for (let j = 0, jl = g.items.length; j < jl; j++) {
                let o = g.items[j];
                o.source = (o.source || []).join(",");
                o.retailFormCode = (o.retailFormCode || []).join(",");
                o.shop = (o.shop || []).join(",");
                o.customer = (o.customer || []).join(",");
                o.retailContractNo = (o.retailContractNo || []).join(",");
                o.times = g.times;
                if (!o._price || o._price.error || o._price.loading) {
                  err = `商品：${o.goodsName}【${this.$goodsSpecConvert(
                    o.goodsSpec
                  )}】没有价格，无法采购`;
                  break;
                } else {
                  delete o._price;
                  delete o._stockInfo;
                  delete o.groupSaleRule;
                  form.items.push(o);
                }
              }
              if (err) break;
            }
          }
          if (!err) {
            delete form._groupSales;

            form._gifts.forEach((o) => {
              if (o.current) {
                delete o.current._price;
                delete o.current._stockInfo;
                o.current.source = (o.current.source || []).join(",");
                o.current.retailFormCode = (
                  o.current.retailFormCode || []
                ).join(",");
                o.current.shop = (o.current.shop || []).join(",");
                o.current.customer = (o.current.customer || []).join(",");
                o.current.retailContractNo = (
                  o.current.retailContractNo || []
                ).join(",");
                form.items.push(o.current);
              }
            });

            delete form._gifts;
          }
        } else {
          reject("请选择商品或者组合销售");
        }
        if (err) {
          reject(err);
        } else if (hasNoSelectPromotion) {
          this.$confirm(
            "当前订单还有促销活动或者整单优惠活动可以参加。<br />确定不参与活动并继续操作吗？",
            "温馨提示",
            {
              type: "warning",
              dangerouslyUseHTMLString: true,
            }
          )
            .then((_) => {
              resolve(form);
            })
            .catch((_) => {
              reject();
            });
        } else {
          resolve(form);
        }
      });
    },
    calcStock(row) {
      let r =
        row._stockInfo.values.warehouseCount - row._stockInfo.values.saleCount;
      if (r < 0) r = 0;
      return r.toFixed(2);
    },
    doSave(callback) {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          this.convertForm()
            .then((form) => {
              this.saving = true;
              let fn;
              if (this.isAdd || !form.id) {
                if (this.mode === "mall" && form.payType === 1) {
                  fn = addAndSubmit;
                }
                fn = add;
              } else {
                fn = edit;
              }
              fn(form)
                .then((res) => {
                  delete res.items;
                  if (this.isAdd)
                    Object.assign(this.form, {
                      id: res.id,
                    });
                  this.isAdd = false;
                  if (this.mode === "window") {
                    this.$emit("submit");
                  }
                  if (typeof callback === "function") callback();
                  else {
                    if (this.mode === "mall") {
                      this.$emit("completed", res);
                    }
                    this.$notify({
                      title: `保存采购单成功`,
                      type: "success",
                      duration: 2500,
                    });
                  }
                })
                .finally((_) => {
                  this.saving = false;
                });
            })
            .catch((err) => {
              if (err)
                this.$alert(err, "系统提示", {
                  type: "error",
                });
            });
        });
    },
    doSubmit() {
      this.$confirm(
        "提交采购单后将不能再进行修改，确定要提交吗？",
        "提交确认",
        {
          type: "warning",
          dangerouslyUseHTMLString: true,
        }
      ).then((res) => {
        this.$refs.form &&
          this.$refs.form.validate().then((_) => {
            this.convertForm().then((form) => {
              this.submiting = true;
              saveAndSubmit(form)
                .then(() => {
                  this.$notify({
                    title: `提交采购单成功`,
                    type: "success",
                    duration: 2500,
                  });
                  this.$emit("submit");
                  this.$emit("close");
                })
                .finally((_) => {
                  this.submiting = false;
                });
            });
          });
      });

      // this.$confirm(
      //   "提交采购单后将不能再进行修改，确定要提交吗？",
      //   "提交确认",
      //   {
      //     type: "warning",
      //     dangerouslyUseHTMLString: true,
      //   }
      // ).then((res) => {
      //   this.doSave((_) => {
      //     if (this.form && this.form.id) {
      //       this.submiting = true;
      //       submit(this.form.id)
      //         .then((res) => {
      //           this.$notify({
      //             title: `提交采购单成功`,
      //             type: "success",
      //             duration: 2500,
      //           });
      //           this.$emit("submit");
      //           this.$emit("close");
      //         })
      //         .finally((_) => {
      //           this.submiting = false;
      //         });
      //     }
      //   });
      // });
    },
    doPay() {
      this.$confirm("完成支付后将不能再进行修改，确定要支付吗？", "支付确认", {
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then((res) => {
        this.doSave((_) => {
          if (this.form && this.form.id) {
            this.$emit("dopay", this.form);
          }
          this.$emit("close");
        });
      });
    },
    doPrint() {
      if (this.form && this.form.id) this.$print("purchase", this.form.id);
    },
    loadStockInfo(g) {
      if (this.mode !== "window") return;
      if (g && g.goodsId) {
        g._stockInfo.loading = true;
        g._stockInfo.error = false;
        g._stockInfo.values = {};
        getGoodsByShopId(g.goodsId, null, false)
          .then((res) => {
            g._stockInfo.values = res;
          })
          .catch((err) => {
            g._stockInfo.error = "获取失败";
          })
          .finally((_) => {
            g._stockInfo.loading = false;
          });
      }
    },
    loadGoodsPrice(g, modifyPrice = false) {
      if (g && g.goodsId && !isNaN(g.count)) {
        g._price.loading = true;
        g._price.error = false;
        g._price.oldValue = null;
        g._price.value = null;
        getPurchaseGoodsPrice({
          goodsId: g.goodsId,
          quantity: g.count,
          sellerId: this.form.sellerId,
          buyerErpId: this.form.buyerErpId,
        })
          .then((res) => {
            let v = res.goodsPrice;
            if (typeof v !== "number") {
              g._price.error = "没有价格";
            } else if (g.promotionId) {
              if (g.promotionJoinType === "groupSale" && g.groupSaleRule) {
                if (g.groupSaleRule.method === "price") {
                  v = g.groupSaleRule.price;
                } else if (g.groupSaleRule.method === "save") {
                  v -= g.groupSaleRule.saveAmount;
                } else if (g.groupSaleRule.method === "discount") {
                  v = Math.round(v * g.groupSaleRule.discount);
                }
              }
            }
            g.distributorDiscount = res.distributorDiscount;
            if (modifyPrice && !g._price.error && res.distributorDiscount) {
              v = Math.round((v * res.distributorDiscount) / 100);
            }
            g._price.oldValue = res.goodsPrice;
            g._price.value = v < 0 ? 0 : v;
            g._price.loading = false;
            this.loadPromotions();
          })
          .catch((err) => {
            console.error(err);
            g._price.error = "获取失败";
            g._price.loading = false;
          });
      }
    },
    handleSellerChange() {
      this.form.items = [];
      this.form._groupSales = [];
      if (this.form.sellerId) {
        this.loadEnablePayType();
        this.loadRebateRule();
        this.goodsIsShow();
      }
    },
    handleBuyerChange() {
      this.form.items = [];
      this.form._groupSales = [];
    },
    loadEnablePayType() {
      // if(!this.form.sellerId) return;
      request({
        url: "api/distributor/payType/" + this.form.sellerId,
        method: "get",
      }).then((res) => {
        this.enablePayType = res;
        if (res === "offline") {
          this.form.payType = 1;
        } else if (res === "online") {
          this.form.payType = 0;
        }
      });
    },
    loadRebateRule() {
      if (this.form) {
        getSet(this.form.sellerId, this.form.id).then((res) => {
          this.rebateRule = res;
        });
      }
    },
    handleContactChange(ov, nv, row) {
      if (row) {
        let phones = [];
        if (row.mobilePhone) phones.push(row.mobilePhone);
        else if (row.officePhone) phones.push(row.officePhone);
        this.form.receiverName = row.name;
        this.form.receiverPhone = phones.join(", ");
      }
    },
    handleAddressChange(ov, nv, row) {
      if (row) {
        Object.assign(this.form, {
          recProvinceCode: row.provinceCode,
          recProvince: row.provinceName,
          recCityCode: row.cityCode,
          recCity: row.cityName,
          recDistrictCode: row.districtCode,
          recDistrict: row.districtName,
          recAddress: row.address,
        });
      }
    },
    calcMinDeliveryDate(o) {
      request({
        url: "api/workday",
        method: "get",
        params: {
          goodsId: o.goodsId,
          days: o.deliveryCycle || 7,
        },
      }).then((res) => {
        o._minShipDate = res;
        if (!o.shipDate || o.shipDate < res) o.shipDate = res;
      });
    },
    goodsIsShow() {
      if (this.form.sellerId) {
        request({
          url: "api/buyLimit",
          method: "get",
          params: {
            sellerId: this.form.sellerId,
            formCode: this.form.formCode,
          },
        }).then((res) => {
          this.purchaseLimit = res;
        });
      }
    },
    resetForm(id, action, extend) {
      this.promotions = [];
      this.minShipDate = this.$now.getDiffDays(6);
      this.defaultShipDate = this.$now.getDiffDays(7);
      this.promotionGoodsRemoved = false;

      if (id) {
        this.isAdd = false;
        this.loading = true;

        get(id)
          .then((res) => {
            if (action === "copy") {
              this.isAdd = true;
              let gc = res.items.length;
              res = {
                id: null,
                sellerId: res.sellerId,
                buyerErpId: res.buyerErpId,
                info: res.info,
                recProvince: res.recProvince,
                recProvinceCode: res.recProvinceCode,
                recCity: res.recCity,
                recCityCode: res.recCityCode,
                recDistrict: res.recDistrict,
                recDistrictCode: res.recDistrictCode,
                recAddress: res.recAddress,
                receiverName: res.receiverName,
                receiverPhone: res.receiverPhone,
                purchaseTime: this.$now.get(),
                useRebate: res.useRebate,
                items: res.items.filter((o) => !o.fromPromotion),
                promotionId: null,
                proWholeOrderId: null,
                _groupSales: [],
                _gifts: [],
                payType: res.payType,
                payStatus: 0,
                status: 0,
              };
              if (res.items.length === 0) {
                this.$alert(
                  "该订单的商品均为促销商品，无法再次采购。",
                  "再次采购限制",
                  {
                    type: "warning",
                  }
                );
                this.$emit("close");
                return false;
              } else if (res.items.length !== gc) {
                this.promotionGoodsRemoved = true;
              }
            }
            let arrs = [],
              gs = {},
              gsIds = {},
              gifts = {},
              gss = [];
            // let params = { sellerId: res.sellerId, formCode: res.formCode };
            if (res.items && res.items.length) {
              res.items.forEach((o) => {
                o._minShipDate = null;
                o._shipDate = o.shipDate;
                if (action === "copy") {
                  o.shipDate = this.defaultShipDate;
                  delete o.id;
                  delete o.formId;
                  delete o.sendCount;
                  delete o.cancelCount;
                  delete o.closeTime;
                  delete o.closeUser;
                  delete o.receivedCount;
                  delete o.thisTimeCount;
                }

                o._id = this.$uuid();
                o.businessId = this.$uuid();
                o.source = o.source ? o.source.split(",") : [];
                o.retailContractNo = o.retailContractNo
                  ? o.retailContractNo.split(",")
                  : [];
                o.retailFormCode = o.retailFormCode
                  ? o.retailFormCode.split(",")
                  : [];
                o.shop = o.shop ? o.shop.split(",") : [];
                o.customer = o.customer ? o.customer.split(",") : [];

                let _ps = [`<div>原价<br />${this.$price(o.price, 4)}</div>`];
                o._finalPrice = o.price;
                if (o.discountAmount) {
                  let _dp =
                    Math.round((o.discountAmount / o.count) * 100) / 100;
                  o._finalPrice -= _dp;
                  _ps.push(`<div>促销优惠<br />${this.$price(_dp, 4)}</div>`);
                }

                o._realRebateAmount = 0;
                if (o.rebateAmount) {
                  let _rp = Math.round((o.rebateAmount / o.count) * 100) / 100;
                  o._finalPrice -= _rp;
                  o._realRebateAmount = _rp * (o.count - o.cancelCount);
                  _ps.push(`<div>返利抵扣<br />${this.$price(_rp, 4)}</div>`);
                }
                o._finalAmount = o._finalPrice * (o.count - o.cancelCount);
                o._finalPrices = _ps.join("<div>&nbsp;-&nbsp;</div>");

                o._stockInfo = {
                  loading: false,
                  error: false,
                  values: null,
                };
                o._price = {
                  loading: false,
                  error: false,
                  oldValue: o.price,
                  value: o.realPrice,
                };
                o._promotions = [];
                o.giftDiscountAmount = 0;
                o.giftDiscountPrice = 0;

                if (res.status === 0) {
                  if (o.promotionId && o.promotionJoinType === "groupSale") {
                    if (!gs.hasOwnProperty(o.groupId)) {
                      gs[o.groupId] = {
                        ids: [],
                        groupSaleId: o.groupSaleId,
                        groupId: o.groupId,
                        groupSaleName: o.groupSaleName,
                        times: o.times,
                        minTimes: o.groupMinBuyQuantity,
                        discountAmount: 0,
                        items: [],
                      };
                      gss.push(gs[o.groupId]);
                    }
                    o.groupSaleRule.quantity = o.count / o.times;
                    gs[o.groupId].ids.push(
                      o.groupSaleItemGoodsId +
                        "_" +
                        o.groupSaleRule.quantity.toFixed(2)
                    );
                    gs[o.groupId].items.push(o);
                  } else if (o.isGift) {
                    gifts[o.giftRuleId] = o;
                  } else {
                    arrs.push(o);
                  }
                  this.calcMinDeliveryDate(o);
                } else {
                  arrs.push(o);
                }
                this.loadStockInfo(o);
              });
            }
            gss.forEach((g) => {
              g.id = g.groupSaleId + "," + g.ids.sort().join(",");
            });
            res.items = arrs;
            res._groupSales = gss;
            res._gifts = [];
            this.gifts = gifts;
            this.form = res;
            this.$nextTick((_) => {
              if (this.editable) {
                arrs.forEach((o) => {
                  this.loadGoodsPrice(o);
                });
                gss.forEach((g) => {
                  g.items.forEach((o) => {
                    this.loadGoodsPrice(o, true);
                  });
                });
                this.loadPromotions();
                this.loadEnablePayType();
                this.loadRebateRule();
                this.goodsIsShow();
              }
              this.$emit(
                "update:title",
                this.editable
                  ? this.isAdd
                    ? "新增采购单"
                    : "编辑采购单"
                  : "查看采购单"
              );
            });
          })
          .finally((_) => {
            this.loading = false;
          });
      } else if (this.user) {
        this.isAdd = true;
        this.gifts = {};
        let nform = Object.assign(
          {
            id: null,
            sellerId: null,
            info: "",
            recProvince: null,
            recProvinceCode: null,
            recCity: null,
            recCityCode: null,
            recDistrict: null,
            recDistrictCode: null,
            recAddress: null,
            receiverName: this.user.userRealName,
            receiverPhone: this.user.phone,
            purchaseTime: this.$now.get(),
            useRebate: true,
            items: [],
            promotionId: null,
            proWholeOrderId: null,
            _groupSales: [],
            _gifts: [],
            payType: 0,
            payStatus: 0,
            status: 0,
          },
          this.defaultAddress
            ? {
                recProvinceCode: this.defaultAddress.provinceCode,
                recProvince: this.defaultAddress.provinceName,
                recCityCode: this.defaultAddress.cityCode,
                recCity: this.defaultAddress.cityName,
                recDistrictCode: this.defaultAddress.districtCode,
                recDistrict: this.defaultAddress.districtName,
                recAddress: this.defaultAddress.address,
              }
            : {},
          this.defaultContact
            ? {
                receiverName: this.defaultContact.name,
                receiverPhone: this.defaultContact.phones,
              }
            : {},
          extend || {}
        );
        (nform._groupSales || []).forEach((gs) => {
          (gs.items || []).forEach((o) => {
            o._minShipDate = null;
            o.shipDate = null;
            this.calcMinDeliveryDate(o);
          });
        });
        this.form = nform;
        (nform.items || []).forEach((o) => {
          o._minShipDate = null;
          o.shipDate = null;
          o._price = {
            loading: false,
            error: false,
            oldValue: null,
            value: null,
          };
          this.loadGoodsPrice(o);
          this.calcMinDeliveryDate(o);
        });
        this.$nextTick((_) => {
          this.$emit(
            "update:title",
            this.editable
              ? this.isAdd
                ? "新增采购单"
                : "编辑采购单"
              : "查看采购单"
          );
          if (this.form.sellerId) {
            this.loadEnablePayType();
            this.loadRebateRule();
            this.goodsIsShow();
          }
          this.loadPromotions();
        });
      }
    },
    handleGoodsAdd(list) {
      if (list && list.length) {
        this.form.items = (this.form.items || []).concat(
          list.map((o) => {
            let _no = {
              _id: this.$uuid(),
              goodsId: o.id,
              goodsSpec: o.specs,
              goodsName: o.name,
              minSalesQuantity: o.minSalesQuantity || 0,
              code: o.code,
              erpCode: o.erpCode,
              source: o.source || [],
              shop: o.shop || [],
              customer: o.customer || [],
              retailFormCode: o.retailFormCode || [],
              retailContractNo: o.retailContractNo || [],
              purchaseLimit: o.purchaseLimit,
              groupId: this.$uuid(),
              discountAmount: 0,
              giftDiscountAmount: 0,
              giftDiscountPrice: 0,
              distributorDiscount: null,
              _price: {
                loading: false,
                error: false,
                oldValue: null,
                value: null,
              },
              count: o.count || o.minSalesQuantity || 1,
              info: o.info || "",
              _stockInfo: {
                loading: false,
                error: false,
                total: 0,
                items: [],
              },
              deliveryCycle: o.deliveryCycle || 7,
              _minShipDate: null,
              shipDate: null,
              _promotions: [],
              businessId: this.$uuid(),
              fromPromotion: o.fromPromotion === true,
              promotionId: null,
              promotionMutex: false,
              wholePromotionId: null,
              wholePromotionName: null,
              wholeGroupId: null,
              singleGoodsGroupId: null,
              singleGoodsLadderId: null,
              promotionJoinType: null,
              groupSaleId: null,
              groupSaleItemGoodsId: null,
              groupSaleName: null,
              groupSaleRule: null,
            };
            this.loadStockInfo(_no);
            this.loadGoodsPrice(_no);
            this.calcMinDeliveryDate(_no);
            return _no;
          })
        );
        this.$refs.form && this.$refs.form.clearValidate();
      }
    },
    handleGoodsRemove(row) {
      let inx = this.form.items.indexOf(row);
      if (inx >= 0) {
        this.form.items.splice(inx, 1);
        this.loadPromotions();
      }
    },

    handleGroupSaleAdd(sel) {
      let list = sel.items;
      if (list && list.length) {
        let sid = "";
        let ids = list
          .map((o) => {
            sid = o.groupSaleId;
            return o.id + "_" + o.count.toFixed(2);
          })
          .sort()
          .join(",");
        ids = sid + "," + ids;
        let exist = this.form._groupSales.find((o) => {
          return o.id === ids;
        });
        if (exist) {
          let nts = exist.times + 1;
          if (nts > sel.maxTimes) nts = sel.maxTimes;
          if (nts !== exist.times) {
            exist.times = nts;
            exist.items.forEach((o) => {
              o.count = o.groupSaleRule.quantity * exist.times;
              this.loadGoodsPrice(o, true);
            });
          }
        } else {
          let g = {
            id: ids,
            groupId: this.$uuid(),
            groupSaleName: "",
            discountAmount: 0,
            times: sel.minTimes,
            minTimes: sel.minTimes,
            maxTimes: sel.maxTimes,
            items: [],
          };
          list.forEach((o) => {
            g.groupSaleName = o.groupSaleName;
            let _no = {
              _id: this.$uuid(),
              goodsId: o.goodsId,
              goodsSpec: o.spec,
              goodsName: o.goodsName,
              minSalesQuantity: o.groupSaleRule.quantity * sel.minTimes,
              discountAmount: 0,
              giftDiscountAmount: 0,
              giftDiscountPrice: 0,
              distributorDiscount: null,
              code: o.code,
              erpCode: o.erpCode,
              _price: {
                loading: false,
                error: false,
                oldValue: null,
                value: null,
              },
              deliveryCycle: o.deliveryCycle || 7,
              _minShipDate: null,
              shipDate: null,
              count: o.groupSaleRule.quantity * sel.minTimes,
              info: "",
              _stockInfo: {
                loading: false,
                error: false,
                total: 0,
                items: [],
              },
              businessId: this.$uuid(),
              fromPromotion: true,
              groupId: g.groupId,
              groupMinBuyQuantity: sel.minTimes,
              promotionId: o.promotionId,
              promotionMutex: o.promotionMutex,
              wholePromotionId: null,
              wholePromotionName: null,
              wholeGroupId: null,
              singleGoodsGroupId: null,
              singleGoodsLadderId: null,
              promotionJoinType: "groupSale",
              groupSaleId: o.groupSaleId,
              groupSalesItemId: o.groupSalesItemId,
              groupSaleItemGoodsId: o.id,
              groupSaleName: o.groupSaleName,
              groupSaleRule: o.groupSaleRule,
              source: [],
              shop: [],
              customer: [],
              retailFormCode: [],
            };

            this.loadStockInfo(_no);
            this.loadGoodsPrice(_no, true);
            this.calcMinDeliveryDate(_no);
            g.items.push(_no);
          });
          this.form._groupSales.push(g);
        }
      }
    },
    handleGroupSaleTimesChange(g) {
      if (typeof g.times !== "number") {
        this.$nextTick((_) => {
          let o = g.items[0];
          g.times = o.count / o.groupSaleRule.quantity;
        });
      } else {
        g.items.forEach((o) => {
          o.count = o.groupSaleRule.quantity * g.times;
          this.loadGoodsPrice(o, true);
        });
      }
    },
    handleGoodsLinkRetail(gi) {
      this.$refs.retailLink && this.$refs.retailLink.set(gi);
    },
    handleGroupSaleRemove(row) {
      let inx = this.form._groupSales.indexOf(row);
      if (inx >= 0) {
        this.form._groupSales.splice(inx, 1);
        this.loadPromotions();
      }
    },
    loadPromotions() {
      let items = [],
        able = true;
      for (let i = 0, l = this.form.items.length; i < l; i++) {
        let o = this.form.items[i];
        if (!o._price || o._price.loading || o._price.error) {
          able = false;
          break;
        } else {
          o.discountAmount = 0;
          if (!o.businessId) o.businessId = this.$uuid();
          items.push({
            id: o.id,
            goodsId: o.goodsId,
            price: o._price.oldValue,
            businessId: o.businessId,
            promotionId: o.promotionId || null,
            promotionJoinType: o.promotionJoinType,
            promotionMutex: o.promotionMutex,
            count: o.count,
            distributorDiscount: o.distributorDiscount,
          });
        }
      }
      if (!able) return;

      for (let i = 0, l = this.form._groupSales.length; i < l; i++) {
        let gs = this.form._groupSales[i];
        for (let x = 0, xl = gs.items.length; x < xl; x++) {
          let o = gs.items[x];
          if (!o._price || o._price.loading || o._price.error) {
            able = false;
            break;
          } else {
            o.discountAmount = 0;
            if (!o.businessId) o.businessId = this.$uuid();
            items.push({
              id: o.id,
              goodsId: o.goodsId,
              price: o._price.oldValue,
              businessId: o.businessId,
              promotionId: o.promotionId || null,
              promotionJoinType: o.promotionJoinType,
              groupSaleItemGoodsId: o.groupSaleItemGoodsId,
              promotionMutex: o.promotionMutex,
              count: o.count,
              distributorDiscount: o.distributorDiscount,
            });
          }
        }
      }
      if (!able) return;

      if (items.length) {
        this.promotionLoading = true;
        request({
          url: "api/order/promotion",
          method: "post",
          data: {
            sellerId: this.form.sellerId,
            promotionId: this.form.promotionId,
            items: items,
          },
        })
          .then((res) => {
            if (res) {
              let oPromotion = null;
              res.promotions.forEach((o) => {
                if (o.method === "gift") {
                  o.giftRules.forEach((gr) => {
                    gr.promotionName = o.name;
                    gr.goodsList.forEach((g) => {
                      g.promotionName = o.name;
                    });
                  });
                }
                if (
                  o.discountAmount > 0 &&
                  this.checkPermission(["PURCHASE_TOTALMONEY"])
                ) {
                  o._label =
                    o.name + " (优惠: " + this.$price(o.discountAmount) + ")";
                } else {
                  o._label = o.name;
                }
                if (this.form.promotionId === o.id) oPromotion = o;
              });
              this.promotions = res.promotions;
              this.form.promotionId = null;
              this.form.proWholeOrderId = null;

              let wholePromotion = null;
              if (
                oPromotion &&
                oPromotion.wholeOrderDetails &&
                oPromotion.wholeOrderDetails.length
              ) {
                wholePromotion = oPromotion.wholeOrderDetails[0];
                if (wholePromotion) {
                  this.form.promotionId = oPromotion.id;
                  this.form.proWholeOrderId = wholePromotion.groupId;
                }
              }

              if (res.orderItems && res.orderItems.length) {
                res.orderItems.forEach((o) => {
                  let g;
                  if (o.promotionJoinType === "groupSale") {
                    for (let i = 0; i < this.form._groupSales.length; i++) {
                      g = this.form._groupSales[i].items.find((fo) => {
                        return fo.businessId === o.businessId;
                      });
                      if (g) break;
                    }
                    if (g) {
                      g._price.value =
                        o.realPrice === null ? g._price.oldValue : o.realPrice;
                      g.discountAmount = o.discountAmount;
                    }
                  } else {
                    g = this.form.items.find((fo) => {
                      return fo.businessId === o.businessId;
                    });
                    if (g) {
                      o.promotions.forEach((o1) => {
                        if (o1.method === "gift") {
                          o1.giftRules.forEach((gr) => {
                            gr.promotionName = o1.name;
                            gr.goodsList.forEach((g1) => {
                              g1.promotionName = o1.name;
                            });
                          });
                        }
                        if (
                          o1.discountAmount > 0 &&
                          this.checkPermission(["PURCHASE_TOTALMONEY"])
                        ) {
                          o1._label =
                            o1.name +
                            " (优惠: " +
                            this.$price(o1.discountAmount) +
                            ")";
                        } else {
                          o1._label = o1.name;
                        }
                      });
                      g._price.value =
                        o.realPrice === null ? g._price.oldValue : o.realPrice;

                      g._promotions = o.promotions;
                      g.singleGoodsGroupId = null;
                      g.singleGoodsLadderId = null;
                      g.promotionId = o.promotionId;
                      g.promotionMutex = false;
                      g.discountAmount = o.discountAmount;
                      g.promotionJoinType = o.promotionJoinType;
                      g.wholePromotionId = null;
                      g.wholePromotionName = null;
                      g.wholeGroupId = null;

                      if (g.promotionId && g.promotionJoinType === "goods") {
                        let p = o.promotions.find((p) => {
                          return p.id === g.promotionId;
                        });
                        if (p && p.rules && p.rules.length) {
                          let r = p.rules[0];
                          g.promotionMutex = p.mutex;
                          g.singleGoodsGroupId = r.groupId;
                          g.singleGoodsLadderId = r.ladderId;
                        } else {
                          g.promotionId = null;
                        }
                      }
                    }
                  }

                  if (
                    g &&
                    !g.promotionMutex &&
                    wholePromotion &&
                    this.form.promotionId
                  ) {
                    g.wholePromotionId = this.form.promotionId;
                    g.wholePromotionName = oPromotion.name;
                    g.wholeGroupId = wholePromotion.groupId;
                  }
                });
              }
            }

            this.calcGifts();
          })
          .finally((_) => {
            this.promotionLoading = false;
          });
      } else {
        this.calcGifts();
      }
    },
    handleGoodsPromotionSet(row, promotionId) {
      if (row.promotionId !== promotionId) {
        let mutex = false;
        if (promotionId !== null) {
          let p = row._promotions.find((o) => {
            return o.id === promotionId;
          });
          mutex = p.mutex;
        }
        // 联动更改其它参与同一活动的商品的活动ID
        this.form.items.forEach((o) => {
          if (
            o.businessId !== row.businessId &&
            o.promotionId &&
            o.promotionId === row.promotionId
          ) {
            /**
             * &&
            o.singleGoodsGroupId === row.singleGoodsGroupId &&
            o.singleGoodsLadderId === row.singleGoodsLadderId
             */
            o.promotionId = promotionId;
            o.promotionMutex = mutex;
            o.promotionJoinType = "goods";
          }
        });
        row.promotionId = promotionId;
        row.promotionMutex = mutex;
        row.promotionJoinType = "goods";
        this.loadPromotions();
      }
    },
    getGoodsPromotionInfo(row) {
      if (row) {
        if (row.promotionId && row.promotionJoinType === "goods") {
          let p = row._promotions.find((o) => {
            return o.id === row.promotionId;
          });
          if (p) {
            return p._label;
          } else {
            row.promotionId = null;
          }
        }
      }
      return "点击选择促销活动";
    },
    convertGiftGoods(g, order) {
      let go = {
        _id: this.$uuid(),
        giftId: g.id,
        giftRuleId: g.giftRuleId,
        code: g.code,
        erpCode: g.erpCode,
        goodsId: g.goodsId,
        goodsSpec: g.specs,
        goodsName: g.name,
        price: g.price,
        realPrice: g.price,
        isGift: true,
        deliveryCycle: g.deliveryCycle,
        _minShipDate: null,
        shipDate: null,
        count: g.quantity,
        info: "",
        source: [],
        shop: [],
        customer: [],
        retailFormCode: [],
        _stockInfo: {
          loading: false,
          error: false,
          total: 0,
          items: [],
        },
      };
      if (order === true) {
        go.wholePromotionId = g.promotionId;
        go.wholePromotionName = g.promotionName;
      } else {
        go.promotionId = g.promotionId;
        go.promotionName = g.promotionName;
      }
      this.loadStockInfo(go);
      this.calcMinDeliveryDate(go);
      return go;
    },
    calcGifts() {
      let gifts = [],
        giftsObj = {};

      let buildGift = (p, gr, order = true) => {
        if (!giftsObj.hasOwnProperty(gr.id)) {
          let o = {
            groupId: gr.groupId,
            ladderId: gr.ladderId,
            giftRuleId: gr.id,
            goodsList: gr.goodsList,
            isOrderPromotion: order,
            promotionId: gr.promotionId,
            promotionName: gr.promotionName,
            giftId: null,
            current: null,
          };
          let exist = null;
          if (this.gifts.hasOwnProperty(gr.id)) {
            exist = (gr.goodsList || []).find((g) => {
              return g.id === this.gifts[gr.id].giftId;
            });
            if (exist) {
              this.gifts[gr.id].count = exist.quantity;
              o.current = this.gifts[gr.id];
              o.giftId = o.current.giftId;
            }
          }
          if (!exist && gr.goodsList.length) {
            o.current = this.convertGiftGoods(gr.goodsList[0], order);
            o.giftId = o.current.giftId;
            this.gifts[gr.id] = o.current;
          }
          giftsObj[gr.id] = o;
          gifts.push(o);
        }
      };

      if (this.form.promotionId && this.promotions && this.promotions.length) {
        let p = this.promotions.find((o) => {
          return o.id === this.form.promotionId;
        });
        if (p && p.method === "gift" && p.giftRules && p.giftRules.length) {
          p.giftRules.forEach((gr) => {
            buildGift(p, gr);
          });
        }
      }

      if (this.form.items && this.form.items.length) {
        this.form.items.forEach((item) => {
          if (item.promotionId) {
            let p = item._promotions.find((o) => {
              return o.id === item.promotionId;
            });
            if (p && p.method === "gift" && p.giftRules && p.giftRules.length) {
              p.giftRules.forEach((gr) => {
                buildGift(p, gr, false);
              });
            }
          }
        });
      }
      this.form._gifts = gifts;
      // console.log(this.gifts);
      // console.log(this.form._gifts);
      this.calcGiftPromotionDiscounts();
    },
    handleGiftSet(giftGroup, giftId) {
      if (giftId !== giftGroup.giftId) {
        let g = giftGroup.goodsList.find((o) => {
          return o.id === giftId;
        });
        if (g) {
          giftGroup.giftId = giftId;
          giftGroup.current = this.convertGiftGoods(
            g,
            giftGroup.isOrderPromotion
          );
        } else {
          giftGroup.giftId = null;
          giftGroup.current = null;
        }
        this.calcGiftPromotionDiscounts();
      }
    },
    calcGiftPromotionDiscounts() {
      let calcedPromotionIds = [];

      // 重置赠品相关优惠信息
      this.form.items.forEach((o) => {
        o.giftDiscountAmount = 0;
        o.giftDiscountPrice = 0;
      });
      this.form._groupSales.forEach((g) => {
        (g.items || []).forEach((o) => {
          o.giftDiscountAmount = 0;
          o.giftDiscountPrice = 0;
        });
      });

      this.form._gifts.forEach((group) => {
        let giftAmounts = 0,
          itemAmounts = 0,
          items = [];

        if (
          group.isOrderPromotion &&
          calcedPromotionIds.indexOf(group.promotionId) < 0
        ) {
          // 如果是整单促销赠品：
          calcedPromotionIds.push(group.promotionId);
          let p = this.promotions.find((o) => {
            return o.id === group.promotionId;
          });

          this.form._gifts.forEach((t) => {
            if (t.promotionId === group.promotionId && t.current) {
              giftAmounts += t.current.price * t.current.count;
            }
          });

          let singleItems = this.form.items.filter((o) => {
            let gInx = p.goodsRanges.findIndex((gr) => {
              return gr.goodsId === o.goodsId;
            });
            if (
              !o.promotionMutex &&
              (p.goodsRange === "all" ||
                (p.goodsRangeSet === "whitelist" && gInx >= 0) ||
                (p.goodsRangeSet === "blacklist" && gInx < 0))
            ) {
              itemAmounts += o._price.oldValue * o.count;
              return true;
            }
            return false;
          });

          let gsItems = [];
          this.form._groupSales.forEach((gs) => {
            gsItems = gsItems.concat(
              (gs.items || []).filter((o) => {
                let gInx = (p.goodsRanges || []).findIndex((gr) => {
                  return gr.goodsId === o.goodsId;
                });
                if (
                  !o.promotionMutex &&
                  (p.goodsRange === "all" ||
                    (p.goodsRangeSet === "whitelist" && gInx >= 0) ||
                    (p.goodsRangeSet === "blacklist" && gInx < 0))
                ) {
                  itemAmounts += o._price.oldValue * o.count;
                  return true;
                }
                return false;
              })
            );
          });

          items = [].concat(singleItems).concat(gsItems);
        } else if (
          !group.isOrderPromotion &&
          calcedPromotionIds.indexOf(group.promotionId) < 0
        ) {
          // 如果是单品促销赠品：
          calcedPromotionIds.push(group.promotionId);

          this.form._gifts.forEach((t) => {
            if (t.promotionId === group.promotionId && t.current) {
              giftAmounts += t.current.price * t.current.count;
            }
          });
          items = this.form.items.filter((o) => {
            if (o.promotionId === group.promotionId) {
              itemAmounts += o._price.oldValue * o.count;
              return true;
            }
            return false;
          });
        }

        let remainGiftAmounts = giftAmounts;
        for (let i = 0, l = items.length; i < l; i++) {
          let o = items[i];

          if (i === l - 1) {
            o.giftDiscountPrice += remainGiftAmounts / o.count;
            o.giftDiscountAmount += remainGiftAmounts;
          } else {
            let perAmount =
              (giftAmounts * ((o._price.oldValue * o.count) / itemAmounts)) /
              o.count;
            let itemDiscountAmount = Math.round(perAmount * o.count);
            o.giftDiscountPrice += perAmount;
            o.giftDiscountAmount += itemDiscountAmount;
            remainGiftAmounts -= itemDiscountAmount;
          }
        }
      });
    },
  },
  mounted() {
    getDefaultContact().then((res) => {
      if (res) {
        let phones = [];
        if (res.mobilePhone) phones.push(res.mobilePhone);
        else if (res.officePhone) phones.push(res.officePhone);
        res.phones = phones.join(", ");
      }
      this.defaultContact = res;
      if (res && this.isAdd) {
        this.form.receiverName = res.name;
        this.form.receiverPhone = res.phones;
      }
    });
    getDefaultAddress().then((res) => {
      this.defaultAddress = res;
      if (res && this.isAdd) {
        this.form.recProvinceCode = this.defaultAddress.provinceCode;
        this.form.recProvince = this.defaultAddress.provinceName;
        this.form.recCityCode = this.defaultAddress.cityCode;
        this.form.recCity = this.defaultAddress.cityName;
        this.form.recDistrictCode = this.defaultAddress.districtCode;
        this.form.recDistrict = this.defaultAddress.districtName;
        this.form.recAddress = this.defaultAddress.address;
      }
    });
  },
};
</script>

<style lang="less" scoped>
.group-sale {
  background-color: #eeeaff;
  &:first-child {
    margin-top: -8px;
  }
}
</style>