<template>
  <div class="app-container" v-loading.fullscreen.lock="closing">
    <div class="head-container">
      <quick-select v-model="query.provinceCode" clearable class="filter-item" url="api/regionalDict/province"
        value-field="code" placeholder="省份" filterable style="width:100px;" @change="query.cityCode = null;" />
      <quick-select v-model="query.cityCode" clearable class="filter-item"
        :url="`api/regionalDict/parent/${query.provinceCode}`" value-field="code" placeholder="城市" filterable
        v-if="query.provinceCode" style="width: 100px;" />
      <el-input disabled placeholder="选择省份" v-else style="width:100px;" class="filter-item" />
      <quick-select v-model="query.buyerId" url="api/distributor" placeholder="经销商" clearable filterable
        @change="reQuery" value-field="enterpriseId" class="filter-item" style="width: 180px;" />
      <quick-select v-model="query.buyerErpId" url="api/distributorErps/list" :params="{ entId: query.buyerId }"
        placeholder="下单账户" value-field="erpId" display-field="name" @change="toQuery" class="filter-item"
        style="width: 180px;" clearable v-if="query.buyerId" />
      <el-select v-model="dateType" placeholder="日期类型" class="filter-item" style="width: 100px" @change="reQuery">
        <el-option v-for="(v, k) in dateTypes" :key="k" :label="v" :value="k" />
      </el-select>
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" style="width: 220px;" unlink-panels
        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions"
        value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="reQuery" />

      <template v-if="checkPermission(['WHOLESALE_ALL', 'WHOLESALE_PAY'])">
        <el-select v-model="query.payType" placeholder="支付方式" style="width: 120px;" class="filter-item" clearable
          @change="query.payStatus = null; reQuery();">
          <el-option v-for="(v, i) in payTypes" :key="i" :label="v" :value="i" />
        </el-select>
        <el-select v-model="query.payStatus" placeholder="付款状态" style="width:120px" class="filter-item" clearable
          @change="reQuery" :disabled="query.payType !== 0 && query.payType !== 1">
          <template v-for="(item, i) in payStatus[query.payType]">
            <el-option v-if="item.hidden !== true" :key="i" :label="item.label" :value="i"></el-option>
          </template>
        </el-select>
      </template>
      <el-select v-model="query.statusList" placeholder="订单状态" style="width:140px" class="filter-item" clearable
        @change="reQuery" multiple collapse-tags>
        <el-option v-for="item in status" :key="item.id" :label="item.label" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="query.isMiniProgram" filterable clearable placeholder="操作来源" class="filter-item"
        style="width: 120px;" @change="reQuery">
        <el-option label="小程序" :value="true" />
        <el-option label="电脑端" :value="false" />
      </el-select>
      <el-input v-model="query.formCode" class="filter-item" :maxlength="20" placeholder="订单号"
        @keypress.enter.native="reQuery" style="width: 160px;" />
      <el-input v-model="query.goodsName" class="filter-item" :maxlength="20" placeholder="商品ERP编码或名称"
        @keypress.enter.native="reQuery" style="width: 160px;" />
      <el-input v-model="query.info" class="filter-item" :maxlength="20" placeholder="备注"
        @keypress.enter.native="reQuery" style="width: 160px;" />
      <el-input v-model="query.promotionName" class="filter-item" :maxlength="40" placeholder="促销活动"
        @keypress.enter.native="reQuery" style="width: 160px;" />
      <el-input v-model="query.groupSaleName" class="filter-item" :maxlength="40" placeholder="组合套餐"
        @keypress.enter.native="reQuery" style="width: 160px;" />
      <div class="filter-item" style="line-height: 32px;">查看未出货商品:</div>
      <el-switch v-model="query.unsend" class="filter-item" @change="reQuery"></el-switch>
      <el-button size="mini" class="filter-item" type="success" icon="el-icon-search" @click="reQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading"
        @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="reQuery">刷新</el-button>
    </div>

    <el-card shadow="never" v-if="count">
      <div class="h">
        <div class="money-count-item">
          <div class="fc-g">原单金额</div>
          <counter class="num" :end-val="count.amount / 100" :decimals="2" :duration="1" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">销售金额</div>
          <counter class="num" :end-val="(count.amount - count.discountAmount) / 100" :decimals="2" :duration="1" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">使用返利金额</div>
          <counter class="num" :class="count.rebateAmount > 0 ? 'fc-e' : ''" :end-val="count.rebateAmount / 100"
            :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">优惠金额</div>
          <counter class="num" :class="count.discountAmount > 0 ? 'fc-e' : ''" :end-val="count.discountAmount / 100"
            :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">发货数量</div>
          <counter class="num" :end-val="count.sendCount" :decimals="2" :duration="1" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">收货数量</div>
          <counter class="num" :end-val="count.receivedCount" :decimals="2" :duration="1" />
        </div>
      </div>
    </el-card>

    <el-table border v-loading="loading" :data="data" :default-sort="{ prop: 'purchaseTime', order: 'descending' }"
      @sort-change="handleSort" style="width: 100%; margin-top: 10px;">
      <el-table-column prop="formCode" label="订单号" width="120" sortable="custom" />
      <!-- <el-table-column prop="erpFormCode" label="ERP单号" width="110" :formatter="v=>{ return v.formCode===v.erpFormCode?'':v.erpFormCode}" /> -->
      <el-table-column prop="retailFormCode" label="零售单单号" width="120" show-overflow-tooltip />
      <el-table-column prop="purchaseTime" label="订单日期" width="100"
        :formatter="r => { return new Date(r.purchaseTime).format('yyyy/MM/dd'); }" sortable="custom" />
      <el-table-column prop="checkTime" label="审核日期" width="100"
        :formatter="r => { return r.checkTime ? new Date(r.checkTime).format('yyyy/MM/dd') : ''; }" sortable="custom" />
      <el-table-column prop="buyerErpId" label="经销商编码" width="150" sortable="custom" />
      <el-table-column prop="buyerName" label="经销商" min-width="200" sortable="custom" show-overflow-tooltip />
      <el-table-column label="商品编码" prop="code" width="160" sortable="custom" />
      <el-table-column label="ERP编码" prop="erpCode" width="160" sortable="custom" />
      <el-table-column label="商品" prop="goodsName" min-width="180" sortable="custom" show-overflow-tooltip />
      <el-table-column prop="goodsSpec" label="规格" min-width="160" :formatter="$goodsSpecConvert" sortable="custom" />
      <el-table-column label="数量" prop="count" sortable="custom" width="80" align="right" />
      <el-table-column label="出厂价" prop="price" sortable="custom" width="100" align="right">
        <template slot-scope="scope">{{ $price(scope.row.price) }}</template>
      </el-table-column>
      <el-table-column label="折扣率" width="90" align="right" :formatter="calcProductDiscountPercent" />
      <el-table-column label="成交价" prop="realPrice" sortable="custom" width="100" align="right">
        <template slot-scope="scope">{{ $price(scope.row.realPrice) }}</template>
      </el-table-column>
      <!-- <el-table-column label="金额" prop="amount" sortable="custom" width="100" align="right">
        <template slot-scope="scope">{{$price(scope.row.amount)}}</template>
      </el-table-column>-->
      <el-table-column label="金额" width="100" align="right">
        <template slot-scope="scope">{{
          $price(scope.row.realPrice * (scope.row.count -
            scope.row.cancelCount))
        }}</template>
      </el-table-column>
      <el-table-column label="整单折扣率" width="90" align="right" :formatter="calcDiscountPercent" />
      <el-table-column label="已发货数量" width="120" prop="sendCount" sortable="custom" />
      <el-table-column label="已收货数量" width="120" prop="receivedCount" sortable="custom" />
      <el-table-column label="已取消数量" width="120" prop="cancelCount" sortable="custom" />
      <el-table-column label="发货日期" prop="shipDate" width="100"
        :formatter="v => { return new Date(v.shipDate).format('yyyy/MM/dd') }" sortable="custom" />
      <el-table-column label="支付方式" width="100" prop="payType" :formatter="r => { return payTypes[r.payType]; }"
        sortable="custom" />
      <el-table-column label="付款情况" align="center" width="105" prop="payStatus" sortable="custom"
        v-if="checkPermission(['WHOLESALE_ALL', 'WHOLESALE_PAY'])">
        <template slot-scope="scope">
          <!-- <el-tag :type="payStatus[scope.row.payStatus].type" :key="scope.row.id">{{payStatus[scope.row.payStatus].label}}</el-tag> -->
          <el-tag :type="payStatus[scope.row.payType][scope.row.payStatus].type"
            :key="scope.row.id">{{ payStatus[scope.row.payType][scope.row.payStatus].label }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="关闭状态" align="center" width="100" prop="status">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.count == scope.row.cancelCount">已关闭</el-tag>
          <el-tag type="info"
            v-else-if="0 < scope.row.cancelCount && scope.row.cancelCount < scope.row.count">部分已关闭</el-tag>
          <el-tag v-else>未关闭</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" align="center" width="100" prop="formStatus" sortable="custom">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.formStatus].type">{{ status[scope.row.formStatus].label }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="促销活动" prop="promotionName" width="120" sortable="custom" show-overflow-tooltip />
      <el-table-column label="组合套餐" prop="groupSaleName" width="120" sortable="custom" show-overflow-tooltip />
      <el-table-column label="备注" prop="info" width="160" sortable="custom" />
      <el-table-column label="操作来源" width="160" sortable="custom">
        <template slot-scope="scope">
          {{ scope.row.isMiniProgram ? "小程序" : "电脑端" }}
        </template>
      </el-table-column>
      <el-table-column width="50" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">查看</el-button>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <e-form ref="form" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";
import { sumDetails } from "@/api/distributorOrder";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import Counter from "vue-count-to";

export default {
  components: { eForm, Counter },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      delLoading: false,
      sort: "purchaseTime,desc",
      closing: false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      payTypes: ["线上支付", "线下支付"],
      payStatus: [
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分付款成功",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "付款成功",
          },
        ],
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分已付款",
          },
          {
            type: "warning",
            label: "部分付款已确认",
          },
          {
            type: "success",
            label: "已付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "已完成",
          },
        ],
      ],
      status: [
        {
          type: "info",
          label: "编辑中",
          id: 0,
        },
        {
          type: "warning",
          label: "待审批",
          id: 1,
        },
        {
          type: "success",
          label: "已确认",
          id: 2,
        },
        {
          type: "danger",
          label: "已拒绝",
          id: 3,
        },
        {
          type: "warning",
          label: "待发货",
          id: 4,
        },
        {
          type: "success",
          label: "部分已发货",
          id: 5,
        },
        {
          type: "success",
          label: "已发货",
          id: 6,
        },
        {
          type: "success",
          label: "部分已收货",
          id: 7,
        },
        {
          type: "success",
          label: "已完成",
          id: 8,
        },
        {
          type: "info",
          label: "已关闭",
          id: 9,
        },
        {
          type: "info",
          label: "已取消",
          id: 10,
        },
        {
          type: "info",
          label: "部分已关闭",
          id: 11,
        },
      ],
      count: null,
      query: {
        provinceCode: null,
        cityCode: null,
        buyerId: null,
        dateRange: [
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          new Date().getTime(),
        ],
        payStatus: null,
        status: null,
        formCode: null,
        goodsName: null,
        unsend: false,
      },
      dateType: "sale",
      dateTypes: {
        "sale": "销售日期",
        "audit": "审核日期"
      },
    };
  },
  created() {
    this.reQuery();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/orderForm/detail/seller";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2 && this.dateType === 'sale') {
        query.beginTime = new Date(new Date(query.dateRange[0]).format("yyyy-MM-dd 00:00:00")).getTime();
        query.endTime = new Date(new Date(query.dateRange[1]).format("yyyy-MM-dd 23:59:59")).getTime();
      }

      if (query.dateRange && query.dateRange.length === 2 && this.dateType === 'audit') {
        query.checkBegTime = new Date(new Date(query.dateRange[0]).format("yyyy-MM-dd 00:00:00")).getTime();
        query.checkEndTime = new Date(new Date(query.dateRange[1]).format("yyyy-MM-dd 23:59:59")).getTime();
      }
      // if (query.dateRange && query.dateRange.length === 2) {
      //   query.beginTime = query.dateRange[0];
      //   query.endTime = query.dateRange[1];
      // }
      delete query.dateRange;
      this.params = Object.assign({ sort: this.sort }, query);
      return true;
    },
    handleSort(sort) {
      if (sort.order == null) this.sort = "";
      else
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      this.toQuery();
    },
    loadSums() {
      sumDetails(this.params).then((res) => {
        this.count = res;
      });
    },
    reQuery() {
      this.toQuery();
      this.loadSums();
    },
    calcProductDiscountPercent(row) {
      let dm = row.price - row.realPrice;
      if (dm <= 0) return "100%";
      else return ((row.realPrice / row.price) * 100).toFixed(2) + "%";
    },
    calcDiscountPercent(row) {
      let dm = row.formTotalMoney - row.formRealTotalMoney;
      if (dm <= 0) return "100%";
      else
        return (
          ((row.formRealTotalMoney / row.formTotalMoney) * 100).toFixed(2) + "%"
        );
    },
    edit(data) {
      this.$refs.form && this.$refs.form.resetForm(data.formId);
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/orderFormFull/download", this.params)
        .then((result) => {
          downloadFile(result, "批发销售明细", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>