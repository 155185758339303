<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select v-model="query.entId" filterable url="api/purchase/seller" value-field="belongTo" display-field="belongToName" auto-select-first-option class="filter-item" @change="reQuery" style="width: 180px;" />
      <!-- <el-date-picker v-if="statementType==='serta'" v-model="query.yearAndMonth" type="month" :clearable="false" value-format="yyyy-M" placeholder="选择会计期间" @change="reQuery" style="width: 160px;" /> -->
      <el-date-picker v-model="query.dateRange" type="daterange" :clearable="false" class="filter-item" unlink-panels range-separator="至" start-placeholder="记录日期起始" end-placeholder="记录日期截止" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="reQuery" style="width: 240px;" />
      <el-select v-model="query.relationFormType" placeholder="单据类型" style="width:170px" class="filter-item" clearable @change="reQuery" multiple collapse-tags>
        <el-option v-for="(v, i) in relationFormType" :key="i" :label="v" :value="i" />
      </el-select>
      <el-input v-model="query.relationFormCode" clearable placeholder="单据编号" style="width: 200px;" class="filter-item" @keyup.enter.native="reQuery" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="reQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="reQuery">刷新</el-button>
    </div>
    <el-card shadow="never" v-if="count">
      <div class="h">
        <div class="money-count-item">
          <div class="fc-g">返利余额</div>
          <counter class="num" :class="count.balance <= 0 ? 'fc-e' : ''" :end-val="count.balance / 100" :decimals="2" :duration="1" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">可用返利金额</div>
          <counter class="num" :class="count.canUserBalance <= 0 ? 'fc-e' : ''" :end-val="count.canUserBalance / 100" :decimals="2" :duration="1" />
        </div>
      </div>
    </el-card>
    <el-table v-loading="loading" row-key="id" :data="data" highlight-current-row empty-text="未找到返利信息">
      <el-table-column label="单据日期" min-width="180" :formatter="r => { return r.recordTime?new Date(r.recordTime).format():''; }" fixed />
      <el-table-column prop="recordType" label="操作" min-width="100" sortable="custom" fixed>
        <template slot-scope="scope">{{recordTypes[scope.row.recordType || 0]}}</template>
      </el-table-column>
      <el-table-column prop="relationFormType" label="单据类型" min-width="120" sortable="custom" fixed>
        <template slot-scope="scope">{{relationFormType[scope.row.relationFormType]}}</template>
      </el-table-column>
      <el-table-column prop="relationFormCode" label="单据编号" min-width="150">
        <template slot-scope="scope">
          <a class="primary" href="javascript:void(0)" v-if="scope.row.recordType == 0" @click="view(scope.row)">{{scope.row.relationFormCode}}</a>
          <span v-else>{{scope.row.relationFormCode}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="info" label="备注" min-width="320" show-overflow-tooltip />
      <el-table-column label="获得返利金" min-width="120" align="right">
        <template slot-scope="scope" v-if="scope.row.rebate < 0">
          {{$price(-scope.row.rebate)}}&emsp;
          <!-- <a class="primary" @click="handleDetail(scope.row)">明细</a> -->
        </template>
      </el-table-column>
      <el-table-column label="使用返利金" min-width="120" align="right">
        <template slot-scope="scope" v-if="scope.row.rebate >= 0">
          {{$price(scope.row.rebate)}}&emsp;
          <!-- <a class="primary" @click="handleDetail(scope.row)">明细</a> -->
        </template>
      </el-table-column>
      <el-table-column prop="rebateBalance" label="返利余额" min-width="120" align="right" :formatter="$price" />
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <e-form ref="form" />
    <flow-detail ref="detail" />
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import { getStatementTypeBySupplier, getSet } from "@/api/capitalPool";
import Counter from "vue-count-to";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import FlowDetail from "../distributor/flow-detail";
import eForm from "@/views/purchase/detail/form";

export default {
  mixins: [initData],
  components: { Counter, FlowDetail,eForm },
  data() {
    return {
      downloadLoading: false,
      queryable: false,
      count: null,
      query: {
        entId: null,
        dateRange: [
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          new Date().getTime(),
        ],
      },
      statementType: null,
      recordTypes: [
        "采购下单",
        "采购付款",
        "退货退款",
        "充值",
        "提现",
        "返利入账",
        "返利出账",
        "费用单下单",
        "费用单付款",
        "其它",
      ],
      relationFormType: {
        order: "采购单",
        // purchase: "采购单",
        orderBack: "退单",
        payForm: "收退款单",
        rebateApply: "返利申请单",
        bill: "费用单",
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/capitalPool/buyer";
      let query = JSON.parse(JSON.stringify(this.query));
      let required = false;
      if (query.entId) {
        if (query.dateRange && query.dateRange.length === 2) {
          required = true;
          query.begTime = query.dateRange[0];
          query.endTime = query.dateRange[1];
        }
        delete query.dateRange;
      }
      if (query.entId && query.yearAndMonth) {
        required = true;
      }
      query.rebateNot = 0;
      if (required) {
        this.params = query;
        this.params.sort = "recordTime,asc";
      } else {
        this.$message.info("请选择供应商和记录日期范围进行搜索。");
      }
      this.queryable = required;
      return required;
    },
    calcCount() {
      if (this.queryable && this.params) {
        getSet(this.params.entId).then((res) => {
          if (res.balance < 0) res.balance;
          this.count = res;
        });
      }
    },
    handleDetail(row) {
      this.$refs.detail &&
        this.$refs.detail.research(row.id, row.rebate < 0 ? 0 : 1);
    },
    getStatementType() {
      if (this.queryable && this.params) {
        getStatementTypeBySupplier(this.params.entId).then((res) => {
          this.statementType = res;
        });
      }
    },
    reQuery() {
      this.current = null;
      this.toQuery();
      this.calcCount();
      this.getStatementType();
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/capitalPool/rebate/download", this.params)
        .then((result) => {
          downloadFile(result, "采购返利明细", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    view(data) {
      this.$refs.form && this.$refs.form.resetForm(data.relationFormId);
    },
  },
  mounted() {
    let now = new Date();
    this.query.dateRange = [
      new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate(),
        0,
        0,
        0
      ).getTime(),
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        23,
        59,
        59
      ).getTime(),
    ];
  },
};
</script>