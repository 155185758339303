<template>
  <div class="app-container v">
    <div class="head-container">
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="reQuery" />
      <el-select v-model="query.statusList" filterable clearable placeholder="订单状态" class="filter-item"  style="width: 170px" @change="reQuery" multiple collapse-tags>
        <el-option v-for="item in status" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>
      <el-select v-model="query.payStatus" filterable clearable placeholder="收款状态" class="filter-item" style="width: 100px" @change="reQuery">
        <el-option v-for="(v,k) in payStatus" :key="k" :label="v" :value="k" />
      </el-select>
      <el-input v-model="query.keywords" class="filter-item" :maxlength="20" placeholder="销售单号/合同号/电话号码搜索" @keypress.enter.native="reQuery" style="width: 220px;" />
      <el-input v-model="query.goodsName" class="filter-item" :maxlength="20" placeholder="商品ERP编码或名称" @keypress.enter.native="reQuery" style="width: 160px;" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="reQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-card shadow="never" v-if="count">
      <div class="h">
        <div class="money-count-item">
          <div class="fc-g">原单金额</div>
          <counter class="num" :end-val="count.amount / 100" :decimals="2" :duration="1" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">销售金额</div>
          <counter class="num" :end-val="count.realAmount / 100" :decimals="2" :duration="1" />
        </div>
      </div>
    </el-card>

    <el-table v-loading="loading" row-key="id" :data="data" border :default-sort="{prop: 'purchaseTime', order: 'descending'}" @sort-change="handleSort" style="margin-top: 10px;" height="200px">
      <el-table-column prop="formCode" label="销售单号" width="120" fixed sortable="custom" />
      <el-table-column prop="purchaseTime" label="单据日期" width="100" :formatter="r => { return new Date(r.purchaseTime).format('yyyy/MM/dd'); }" sortable="custom" />
      <el-table-column prop="shopName" label="门店" min-width="200" show-overflow-tooltip sortable="custom" />
      <el-table-column prop="contractNo" label="商场合同号" width="120" show-overflow-tooltip sortable="custom" />
      <el-table-column label="商品编码" prop="code" width="160" sortable="custom" />
      <el-table-column label="ERP编码" prop="erpCode" width="160" sortable="custom" />
      <el-table-column label="商品" prop="goodsName" min-width="200" sortable="custom" />
      <el-table-column prop="specs" label="规格" min-width="160" :formatter="$goodsSpecConvert" sortable="custom" />
      <el-table-column label="数量" prop="quantity" sortable="custom" width="80" align="right" />
      <el-table-column label="原价" prop="price" sortable="custom" width="100" align="right">
        <template slot-scope="scope">{{$price(scope.row.price)}}</template>
      </el-table-column>
      <el-table-column label="折扣率" width="90" align="right" :formatter="calcProductDiscountPercent" />
      <el-table-column label="成交价" prop="price" sortable="custom" width="100" align="right">
        <template slot-scope="scope">{{$price(scope.row.realPrice)}}</template>
      </el-table-column>
      <el-table-column label="金额" prop="amount" sortable="custom" width="100" align="right">
        <template slot-scope="scope">{{$price((scope.row.quantity-scope.row.cancelCount)*scope.row.realPrice)}}</template>
        <!-- <template slot-scope="scope">{{$price(scope.row.realTotalMoney)}}</template> -->
      </el-table-column>
      <el-table-column label="已发货数量" width="120" prop="sendCount" sortable="custom" align="right" />
      <el-table-column label="已取消数量" width="120" prop="cancelCount" sortable="custom" align="right" />
      <el-table-column label="现场提货" width="120" prop="sendType" sortable="custom" align="center">
        <template slot-scope="scope">{{scope.row.sendType?'否':'是'}}</template>
      </el-table-column>
      <el-table-column label="交货日期" width="120" prop="deliveryDate" sortable="custom" :formatter="v=>{return v.deliveryDate?new Date(v.deliveryDate).format('yyyy/MM/dd'):''}" />
      <el-table-column prop="salesmanName" label="销售人员" width="120" show-overflow-tooltip sortable="custom" />
      <el-table-column prop="customerName" label="客户名称" width="100" sortable="custom" />
      <el-table-column prop="customerPhone" label="客户电话" width="110" show-overflow-tooltip />
      <el-table-column prop="customerAddress" label="客户收货地址" min-width="120" show-overflow-tooltip sortable="custom">
        <template slot-scope="scope">{{scope.row.provinceName+scope.row.cityName+scope.row.districtName+scope.row.customerAddress}}</template>
      </el-table-column>
      <el-table-column prop="realTotalMoney" label="订单金额" width="120" align="right" :formatter="$price" sortable="custom" />
      <el-table-column prop="payAmount" label="订单已收款" width="120" align="right" sortable="custom">
        <template slot-scope="scope">
          <span :class="scope.row.payAmount >= scope.row.realTotalMoney ? 'fc-s' : ''">{{$price(scope.row.payAmount)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单待收款" width="120" align="right">
        <template slot-scope="scope">
          <span :class="scope.row.payAmount < scope.row.realTotalMoney ? 'fc-e' : ''">{{$price(scope.row.realTotalMoney-scope.row.payAmount)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单折扣率" width="90" align="right" :formatter="calcDiscountPercent" />
      <!-- <el-table-column prop="deliveryDate" label="交货日期" width="90" :formatter="r => { return r.deliveryDate?new Date(r.deliveryDate).format('yyyy/MM/dd'):''; }" /> -->
      <el-table-column prop="remarks" label="订单摘要" min-width="120" show-overflow-tooltip />
      <el-table-column prop="status" label="状态" width="90" align="center" sortable="custom">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="已上报" width="90" align="center" prop="sendNotice" sortable="custom">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.sendNotice"></i>
        </template>
      </el-table-column>
      <el-table-column width="50px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="view(scope.row)">查看</el-button>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <eForm ref="form" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";
import { sumDetails } from "@/api/shopOrder";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import { mapGetters } from "vuex";
import Counter from "vue-count-to";

export default {
  components: { eForm, Counter },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      delLoading: false,
      //时间查询
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      payStatus: {
        all: "全部已收",
        part: "部分已收",
        zero: "待收款",
      },
      status: {
        // input: { key: "input", label: "录入状态", type: "info" },
        // submit: { key: "submit", label: "提交状态", type: "" },
        // auditing: { key: "auditing", label: "审核中", type: "" },
        pass: { key: "pass", label: "审核通过", type: "success" },
        // fail: { key: "fail", label: "审批未通过", type: "danger" },
        // callback: { key: "callback", label: "退回修改", type: "info" },
        unsend: { key: "unsend", label: "待发货", type: "info" },
        sendPart: { key: "sendPart", label: "部分已发货", type: "success" },
        sended: { key: "sended", label: "已发货", type: "success" },
        closed: { key: "closed", label: "已关闭", type: "info" },
        // cancel: { key: "cancel", label: "已取消", type: "info" }
      },
      sort: "purchaseTime,desc",
      count: null,
      query: {
        shopId: null,
        dateRange: null,
        status: null,
        payStatus: null,
        keywords: null,
        goodsName: null,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.reQuery();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/shop/orderFormFull";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign(
        {
          page: this.page,
          size: this.size,
          sort: this.sort,
          salesmanId: this.user.id,
        },
        query
      );
      this.params.statusList = [
        "pass",
        "unsend",
        "sendPart",
        "sended",
        "closed",
      ];
      return true;
    },
    handleSort(sort) {
      if (sort.order == null) this.sort = "";
      else
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      this.toQuery();
    },

    loadSums() {
      sumDetails(this.params).then((res) => {
        this.count = res;
      });
    },
    reQuery() {
      this.toQuery();
      this.loadSums();
    },
    calcProductDiscountPercent(row) {
      let dm = row.price - row.realPrice;
      if (dm <= 0) return "100%";
      else return ((row.realPrice / row.price) * 100).toFixed(2) + "%";
    },
    calcDiscountPercent(row) {
      let dm = row.totalMoney - row.realTotalMoney;
      if (dm <= 0) return "100%";
      else
        return ((row.realTotalMoney / row.totalMoney) * 100).toFixed(2) + "%";
    },
    view(data) {
      let d = JSON.parse(JSON.stringify(data));
      d.id = d.formId;
      this.$refs.form && this.$refs.form.resetForm(d);
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/shop/orderFormFull/download", this.params)
        .then((result) => {
          downloadFile(result, "零售销售明细", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>