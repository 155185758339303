<template>
  <div class="app-container v">
    <div class="head-container">
      <!-- <el-select v-model="query.status" class="filter-item" clearable placeholder="状态" style="width: 120px">
        <template v-for="(o, i) in status">
          <el-option :key="o.label" :label="o.label" :value="i" v-if="i>0" />
        </template>
      </el-select>-->
      <quick-select v-model="query.sellerId" filterable url="api/purchase/seller" placeholder="供应商" value-field="belongTo" display-field="belongToName" clearable @change="handleSellerChange" class="filter-item" style="width: 180px;" auto-select-first-option />
      <quick-select v-model="query.buyerErpId" filterable url="api/distributorErps/list" placeholder="下单账户" value-field="erpId" display-field="name" @change="toQuery" class="filter-item"  style="width: 180px;" auto-select-first-option/>
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <el-input v-model="query.docNum" class="filter-item" :maxlength="20" placeholder="输入发货单号搜索" @keypress.enter.native="toQuery" clearable style="width: 220px;" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <!-- <el-card shadow="never" v-if="count">
      <div class="h" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
        <div class="money-count-item">
          <div class="fc-g">我的余额</div>
          <counter class="num" :class="count.moneyBalance < 0 ? 'fc-e' : ''" :end-val="count.moneyBalance / 100" :decimals="2" :duration="1" />
        </div>
      </div>
    </el-card> -->

    <el-table v-loading="loading" row-key="id" :data="data" height="200px">
      <el-table-column prop="formCode" label="单号" width="120" />
      <el-table-column label="单据日期" width="100" :formatter="r => { return new Date(r.createAt).format('yyyy/MM/dd'); }"/>
      <el-table-column prop="goodsPriceAmount" label="单据金额" width="120" align="right" :formatter="$price" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])"/>
      <el-table-column prop="receiverName" label="收货人" min-width="200" />
      <el-table-column label="收货地址" min-width="240" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.recProvince}}{{scope.row.recDistrict}}{{scope.row.recCity}}{{scope.row.recAddress}}</template>
      </el-table-column>
      <!-- <el-table-column label="付款情况" align="center" width="100">
        <template slot-scope="scope">
          <el-tag :type="payStatus[scope.row.payStatus].type">{{payStatus[scope.row.payStatus].label}}</el-tag>
        </template>
      </el-table-column>-->
      <el-table-column label="状态" align="center" width="100">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="50" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="view(scope.row)">查看</el-button>
          <!-- <el-button size="mini" type="text" @click="rebate(scope.row)" v-if="scope.row.payStatus !== 'pay' && scope.row.payStatus !== 'finish'">使用返利</el-button>
          <el-button size="mini" type="text" @click="inbound(scope.row)" v-if="scope.row.status !== 'received'">入库</el-button>-->
        </div>
      </el-table-column>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout=" prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <eForm ref="form" @inbound="inbound" />
    <rebate-form ref="rebateForm" @complete="init" />
    <inbound-form ref="inboundForm" />
  </div>
</template>


<script>
import eForm from "./form";
import rebateForm from "./rebate-form";
import inboundForm from "../../warehouse/inbound/form";
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { getBySupplier } from "@/api/capitalPool";
import Counter from "vue-count-to";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  components: { eForm, rebateForm, inboundForm, Counter },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      count: null,
      payTypes: ["线上支付", "线下支付"],
      status: {
        unsend: {
          type: "info",
          label: "待审核",
        },
        sended: {
          type: "success",
          label: "已审核",
        },
        received: {
          type: "success",
          label: "已审核",
        },
      },
      payStatus: {
        unpay: {
          type: "info",
          label: "未付款",
        },
        pay_part: {
          type: "warning",
          label: "部分已付款",
        },
        pay_part_check: {
          type: "warning",
          label: "部分收款已确认",
        },
        pay: {
          type: "success",
          label: "已付款",
        },
        failed: {
          type: "danger",
          label: "付款失败",
        },
        finish: {
          type: "primary",
          label: "已完成",
        },
      },
      order: {
        show: false,
        loading: false,
        current: null,
      },
      query: {
        sellerId: null,
        dateRange: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/orderSendForm/forBuyer";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.startDate = query.dateRange[0];
        query.endDate = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign(
        { status: query.status, formType: 0, sort: "createAt,desc" },
        query
      );
      return true;
    },
    loadBalance() {
      if (this.query.sellerId) {
        getBySupplier(this.query.sellerId).then((res) => {
          this.count = res;
        });
      }
    },
    handleSellerChange() {
      // this.loadBalance();
      this.toQuery();
    },
    view(data, lock = false) {
      this.$refs.form && this.$refs.form.resetForm(data, lock);
    },
    rebate(data) {
      this.$refs.rebateForm && this.$refs.rebateForm.load(data);
    },
    inbound(data) {
      this.$refs.inboundForm && this.$refs.inboundForm.resetForm(null, 1, data);
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      this.params.page = 0;
      this.params.size = this.total;
      download("api/orderSendForm/forBuyer/download", this.params)
        .then((result) => {
          downloadFile(result, "发货通知数据", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>