<template>
  <div class="app-container flex v">
    <div class="head-container">
      <template v-if="$erp.enable()">
        <quick-select v-model="query.entId" filterable url="api/distributor" placeholder="经销商" value-field="enterpriseId" clearable class="filter-item" style="width: 180px;" @change="toQuery" />
        <quick-select url="api/warehouse" :params="{entId: query.entId}" v-model="query.warehouseId" filterable collapse-tags placeholder="仓库" class="filter-item" @change="toQuery" clearable style="width: 180px;" v-if="query.entId" />
      </template>
      <template v-else>
        <quick-select url="api/warehouse" v-model="query.warehouseId" filterable collapse-tags placeholder="仓库" class="filter-item" @change="toQuery" clearable style="width: 180px;" />
      </template>
      <div class="c h">
        <el-date-picker v-model="query.formTimeMax" value-format="yyyy-MM-dd 00:00:00" placeholder="开始日期" style="width:130px;" class="filter-item" />
        <el-date-picker v-model="query.formTimeMin" value-format="yyyy-MM-dd 23:59:59" placeholder="截止日期" style="width:130px;" class="filter-item" />
      </div>
      <el-select v-model="query.formType" placeholder="出入库类型" style="width:150px" class="filter-item" clearable @change="toQuery">
        <el-option v-for="(v,k) in storageTypes" :key="k" :label="v" :value="k"></el-option>
      </el-select>
      <el-select v-model="query.inoutType" placeholder="出入库方向" style="width:100px" class="filter-item" clearable @change="toQuery">
        <el-option :value="0" label="入库" />
        <el-option :value="1" label="出库" />
      </el-select>
      <quick-select url="api/brand" v-model="query.brandId" :label.sync="query.brandName" filterable placeholder="品牌" class="filter-item" @change="toQuery" clearable style="width: 160px;" />
      <quick-cascader class="filter-item" v-model="query.seriesId" :label.sync="query.seriesName" url="api/series/tree" :params="{enabled: true}" root-key @change="toQuery" check-strictly filterable clearable placeholder="系列" expand-trigger="hover" style="width: 160px;" />
      <el-select v-model="query.isMiniProgram" filterable clearable placeholder="操作来源" class="filter-item" style="width: 120px;" @change="toQuery">
        <el-option label="小程序" :value="true" />
        <el-option label="电脑端" :value="false" />
      </el-select>
      <input class="filter-item el-input__inner" type="number" v-model.number="query.realCountMin" :min="0.01" :precision="2" placeholder="出入库最小" style="width: 110px;" />
      <input class="filter-item el-input__inner" type="number" v-model.number="query.realCountMax" :min="0.01" :precision="2" placeholder="出入库最大" style="width: 110px;" />
      <el-input v-model="query.goodsName" :maxlength="20" clearable placeholder="输入商品名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.code" :maxlength="20" clearable placeholder="商品编码" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.formCode" :maxlength="20" clearable placeholder="出入库单编号" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.dependFormCode" :maxlength="20" clearable placeholder="关联单号" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.dependFormContractNo" :maxlength="20" clearable placeholder="关联单合同号" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.info" :maxlength="20" clearable placeholder="出入库备注" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-card shadow="never" v-if="params">
      <div class="h">
        <template v-if="!params.inoutType">
          <div class="money-count-item">
            <div class="fc-g">入库数量</div>
            <counter class="num" :end-val="sum[0].realCount" :decimals="0" :duration="1" />
          </div>
          <div class="money-count-item" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
            <div class="fc-g">入库金额</div>
            <counter class="num" :end-val="sum[0].amount / 100" :decimals="2" :duration="1" />
          </div>
        </template>
        <template v-if="params.inoutType === 1 || params.inoutType == null || params.inoutType === ''">
          <div class="money-count-item">
            <div class="fc-g">出库数量</div>
            <counter class="num" :end-val="sum[1].realCount" :decimals="0" :duration="1" />
          </div>
          <div class="money-count-item" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
            <div class="fc-g">出库金额</div>
            <counter class="num" :end-val="sum[1].amount / 100" :decimals="2" :duration="1" />
          </div>
        </template>
      </div>
    </el-card>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" :default-sort="{prop: 'formTime', order: 'descending'}" @sort-change="handleSortChange" stripe style="width: 100%;" height="100px">
      <!-- <el-table-column prop="alias" label="经销商" width="120px" /> -->
      <el-table-column prop="code" label="商品编码" width="140" fixed />
      <el-table-column prop="goodsName" label="商品名称" width="240px" fixed />
      <el-table-column prop="specName" label="商品规格" width="200px" show-overflow-tooltip>
        <template slot-scope="scope">{{$goodsSpecConvert(scope.row.specName)}}</template>
      </el-table-column>
      <el-table-column prop="brandName" label="品牌" width="120px" />
      <el-table-column prop="seriesName" label="系列" width="120px" />
      <el-table-column prop="alias" label="经销商" width="150px" show-overflow-tooltip v-if="$erp.enable()" />
      <el-table-column prop="warehouseName" label="库房名称" width="120px" />
      <el-table-column prop="formTime" label="单据日期" width="100px" sortable="custom" :formatter="r => {return new Date(r.formTime).format('yyyy-MM-dd');}" />
      <el-table-column prop="info" label="备注" width="120px" show-overflow-tooltip />
      <el-table-column label="计划入库" min-width="80px">
        <template slot-scope="scope">{{scope.row.inoutType==0 ?scope.row.goodsCount:""}}</template>
      </el-table-column>
      <el-table-column label="计划出库" min-width="80px">
        <template slot-scope="scope">{{scope.row.inoutType==1 ? scope.row.goodsCount:""}}</template>
      </el-table-column>
      <el-table-column label="实际入库" min-width="80px">
        <template slot-scope="scope">{{scope.row.inoutType==0 ?scope.row.realCount:""}}</template>
      </el-table-column>
      <el-table-column label="实际出库" min-width="80px">
        <template slot-scope="scope">{{scope.row.inoutType==1 ? scope.row.realCount:""}}</template>
      </el-table-column>
      <el-table-column prop="goodsPrice" label="单价" width="110" :formatter="$price" align="center" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])" />
      <el-table-column prop="specName" label="规格" min-width="160" :formatter="$goodsSpecConvert" />
      <el-table-column prop="formType" label="单据类型" min-width="130" :formatter="r => {return formTypes[r.formType];}" />
      <el-table-column prop="formCode" label="单据编号" width="120px" />
      <el-table-column prop="dependFormCode" label="关联单号" width="120px" />
      <el-table-column prop="contractNo" label="关联合同号" width="120px" />
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import request from "@/utils/request";
import Counter from "vue-count-to";

export default {
  mixins: [initData],
  components: { Counter },
  data() {
    return {
      downloadLoading: false,
      erpable: false,
      formTypes: [
        "其他",
        "采购入库",
        "销售发货",
        "退货入库",
        "退货出库",
        "调拨入库",
        "调拨出库",
        "门店销售退货入库",
        "门店销售出库",
      ],
      storageTypes: {
        0: "其他",
        1: "采购入库",
        2: "销售发货",
        3: "退货入库",
        4: "退货出库",
        5: "调拨入库",
        6: "调拨出库",
        7: "门店销售退货入库",
        8: "门店销售出库",
      },
      params: {},
      query: {
        entId: null,
        warehouseId: null,
        formTimeMax: null,
        formTimeMin: null,
        brandId: null,
        brandName: null,
        seriesId: null,
        seriesName: null,
        goodsName: null,
        realCountMin: null,
        realCountMax: null,
        dependFormCode: null,
        code: null,
        info: null,
        formType: null, //出入库类型
        inoutType: null, //出入库方向 0-入库   1-出库
      },
      sort: "formTime,desc",
      sum: [
        {
          realCount: 0,
          amount: 0,
        },
        {
          realCount: 0,
          amount: 0,
        },
      ],
    };
  },

  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = this.$erp.enable()
        ? "api/inoutForm/items/seller"
        : "api/inoutForm/items/buyer";
      let params = JSON.parse(JSON.stringify(this.query));
      params.sort = this.sort;
      if (params.formTimeMax) {
        params.formTimeMax = new Date(params.formTimeMax).getTime();
      }
      if (params.formTimeMin) {
        params.formTimeMin = new Date(params.formTimeMin).getTime();
      }
      this.params = params;
      this.loadSummary();
      return true;
    },
    handleSortChange(e) {
      if (e.prop) {
        if (e.order === "ascending") {
          this.sort = `${e.prop},asc`;
          this.toQuery();
        } else if (e.order === "descending") {
          this.sort = `${e.prop},desc`;
          this.toQuery();
        }
      }
    },
    loadSummary() {
      let url = this.$erp.enable()
        ? "api/inoutForm/sum/seller"
        : "api/inoutForm/sum/buyer";
      if (this.params.inoutType == null || this.params.inoutType === "") {
        Promise.all([
          request({
            url,
            method: "get",
            params: Object.assign({}, this.params, { inoutType: 0 }),
          }),
          request({
            url,
            method: "get",
            params: Object.assign({}, this.params, { inoutType: 1 }),
          }),
        ]).then((arr) => {
          this.sum = arr;
        });
      } else {
        request({
          url,
          method: "get",
          params: this.params,
        }).then((res) => {
          this.sum[this.params.inoutType] = res;
        });
      }
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      if (this.$erp.enable()) {
        this.params.isFactory = true;
      }
      download("api/inoutForm/items/buyer/download", this.params)
        .then((result) => {
          downloadFile(result, "出入库流水", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>