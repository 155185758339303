<template>
  <div class="_content_box v" v-if="result">
    <div class="h c padding-20 fc-w no-flex" style="background-color: #606266;">
      <template v-for="(e, i) in result.ent">
        <img :key="'logo_' + i" :src="e.logo" style="height: 48px;" />
        <div v-if="i < result.ent.length - 1" style="height: 48px; width: 1px; background-color: #707277; margin: 0 20px;"></div>
      </template>
      <div class="flex" style="padding-left: 40px;">
        <div style="font-size: 28px;">欢迎使用DMS</div>
        <div style="font-size: 12px; opacity: .5;">WELCOME TO DMS</div>
      </div>
      <div class="money-count-item">
        <div class="fs-mini">经销商数量</div>
        <counter class="num" :end-val="result.distributorCount" :decimals="0" :duration="1" style="font-size: 32px;" />
      </div>
    </div>
    <div class="h flex">
      <div ref="map" class="flex" style="height: 680px;"></div>
      <div class="scroll-able" v-if="services && services.length">
        <div class="padding-10" v-for="g in services" :key="g.entName">
          <div class="padding-10">
            <b>{{g.entName}}QQ客服</b>
          </div>
          <template v-for="s in g.items">
            <a class="_service_item" :key="s.id" :href="`tencent://message/?uin=${s.qq}&Site=${host}&Menu=yes`">
              <i class="fab fa-qq"></i>
              <div class="flex">
                <b>{{s.name}}</b>
                <br />
                <span>{{s.info}}</span>
              </div>
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import Counter from "vue-count-to";

export default {
  components: { Counter },
  data() {
    return {
      result: null,
      chart: null,
      host: "",
      services: [],
    };
  },
  methods: {
    draw() {
      try {
        let ds = this.result.info
          .filter((o) => {
            return o.lat && o.lng;
          })
          .map((o) => {
            return {
              name: o.name,
              value: [o.lng, o.lat, o.value],
            };
          });

        if (!this.chart) {
          this.chart = echarts.init(this.$refs.map, "light");
        }
        this.chart.clear();

        this.chart.setOption({
          tooltip: {
            show: false,
          },

          bmap: {
            center: [104.114129, 37.550339],
            zoom: 5,
            roam: true,
            mapStyle: {
              styleJson: [
                {
                  featureType: "water",
                  elementType: "all",
                  stylers: {
                    color: "#d1d1d1",
                  },
                },
                {
                  featureType: "land",
                  elementType: "all",
                  stylers: {
                    color: "#f3f3f3",
                  },
                },
                {
                  featureType: "railway",
                  elementType: "all",
                  stylers: {
                    visibility: "off",
                  },
                },
                {
                  featureType: "highway",
                  elementType: "all",
                  stylers: {
                    color: "#fdfdfd",
                  },
                },
                {
                  featureType: "highway",
                  elementType: "labels",
                  stylers: {
                    visibility: "off",
                  },
                },
                {
                  featureType: "arterial",
                  elementType: "geometry",
                  stylers: {
                    color: "#fefefe",
                  },
                },
                {
                  featureType: "arterial",
                  elementType: "geometry.fill",
                  stylers: {
                    color: "#fefefe",
                  },
                },
                {
                  featureType: "poi",
                  elementType: "all",
                  stylers: {
                    visibility: "off",
                  },
                },
                {
                  featureType: "green",
                  elementType: "all",
                  stylers: {
                    visibility: "off",
                  },
                },
                {
                  featureType: "subway",
                  elementType: "all",
                  stylers: {
                    visibility: "off",
                  },
                },
                {
                  featureType: "manmade",
                  elementType: "all",
                  stylers: {
                    color: "#d1d1d1",
                  },
                },
                {
                  featureType: "local",
                  elementType: "all",
                  stylers: {
                    color: "#d1d1d1",
                  },
                },
                {
                  featureType: "arterial",
                  elementType: "labels",
                  stylers: {
                    visibility: "off",
                  },
                },
                {
                  featureType: "boundary",
                  elementType: "all",
                  stylers: {
                    color: "#fefefe",
                  },
                },
                {
                  featureType: "building",
                  elementType: "all",
                  stylers: {
                    color: "#d1d1d1",
                  },
                },
                {
                  featureType: "label",
                  elementType: "labels.text.fill",
                  stylers: {
                    color: "#999999",
                  },
                },
              ],
            },
          },
          series: [
            {
              name: "总销售量",
              type: "effectScatter",
              coordinateSystem: "bmap",
              symbolSize: function (val) {
                return val[2] / 108697500;
              },
              label: false,
              data: ds,
            },
          ],
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    let host = window.location.host;
    if (/^localhost/gi.test(host)) host = "testback.adinghuo.com";
    this.host = host;

    request({
      url: "@host:analysis;api/mainPage/info",
      method: "post",
    }).then((res) => {
      this.result = res;
      this.$nextTick((_) => {
        this.draw();
      });
    });

    request({
      url: "api/qqService/forBuyer",
      method: "get",
    }).then((res) => {
      let sObj = {},
        slist = [];
      (res || []).forEach((o) => {
        let l = sObj[o.entId];
        if (!l) {
          l = {
            entName: o.entName,
            items: [],
          };
          sObj[o.entId] = l;
          slist.push(l);
        }
        l.items.push(o);
      });
      this.services = slist;
    });
  },
};
</script>

<style lang="less" scoped>
._content_box {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    border: rgba(0, 0, 0, 0.1) solid 1px;
    content: "";
    pointer-events: none;
  }

  ._service_item {
    max-width: 240px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    > i {
      font-size: 20px;
      opacity: 0.3;
      margin-right: 8px;
    }
    span {
      font-size: 12px;
      opacity: 0.6;
    }

    &:hover {
      color: #409eff;
    }
  }
}
</style>